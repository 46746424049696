import '../styles/input.comp.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';

interface Props {
    label?: string;
    type?: string;
    placeholder?: string;
    value: string;
    disabled?: boolean;
    onChange: (v: string) => void;
    onEnter?: () => void;
    desc?: string;
}

export const Input = (props: Props) => {
    const [vis, setVis] = useState(false);

    // Handle the type
    const type = props.type
        ? props.type === 'password'
            ? vis
                ? 'text'
                : 'password'
            : props.type
        : 'text';

    // Listen for enter events
    const onEnter = (e: any) => {
        if (e.key === 'Enter') {
            if (props.onEnter) {
                props.onEnter();
            }
        }
    };

    return (
        <div className="input-wrapper" data-tooltip-id="input-tooltip" data-tooltip-content={props.desc ? props.desc : ""}>
            {props.label && <label>{props.label}</label>}

            <input
                onKeyDown={onEnter}
                placeholder={props.placeholder || ''}
                value={props.value}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e.target.value)}
                type={type}
            />

            {/* If this is a password input, add in the reveal password/hide */}
            {props.type === 'password' && (
                <div className="reveal-con">
                    <FontAwesomeIcon
                        icon={vis ? faEyeSlash : faEye}
                        onClick={() => setVis(!vis)}
                    />
                </div>
            )}

            <Tooltip id="input-tooltip" place="bottom" style={{ maxWidth: 250, backgroundColor: "#232323", color: "#fff", zIndex: 10000 }} />

        </div>
    );
};
