import "../styles/filter-bar-layout.scss";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

export const FilterBarLayout = (props: Props) => {
    return (
        <div className="filter-bar-layout">
            {props.children}
        </div>
    )
}