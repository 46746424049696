import { Status } from "../comps/StatusBar";
import { Team } from "./team-model";

export enum AmType {
    SOLD = "SOLD",
    TEST = "TEST",
    ECO = "ECO"
}

export enum OrderStatus {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED"
}

export enum OrderType {
    VPA = "VPA",
    AM = "AM"
}

export interface Order {
    address: any;
    id: number;
    team_id: number;
    team: Team;
    created_at: Date;
    updated_at: Date;
    status: OrderStatus;
    facebook_ad_id?: string;
    clickup_id?: string;
    order_type: OrderType;

    vpa_order?: any;
    am_order?: any;
    report_id?: any;
}

export interface NewAmOrder {
    address?: string;
    notes?: string;
    date: Date;
    media: string[];
    team_id?: number; // If none provided creates a new team
    budget: number; // Convert to cents
    type: AmType;
}

export interface NewVpaOrder {
    address: string;
    notes: string;
    start_date: Date;
    end_date: Date;
    package_id: number;
    package?: Package;
    range?: any;
    team?: Team;
}

export interface Package {
    id: number;
    name: string;
    impressions: number;
    price: number;
    min_days: number;
    max_days: number;
    list_order: number;
    desc: string;
    clickup_id: string;
    team_id: number;
    agency_id: number;
    corp_id: number;
    package_options: PackageOption[];
}

export interface PackageOption {
    id: number;
    name: string;
    checked: boolean;
    package_id: number;
    list_order: number;
    package: Package;
}
