import '../styles/view-header.comp.scss';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthBubble } from './AuthBubble';

interface Props {
    title: string;
    back?: boolean;
    menu?: ReactNode; // Optional options, header menuz
}

export const ViewHeader = (props: Props) => {
    const navigate = useNavigate();

    const go_back = () => {
        // TODO: We need to look at the history object to see if we are still in the same domain

        // Get last page url
        navigate(-1);
    }

    return (
        <div className="view-header">
            <div className="left">
                {props.back && (
                    <div className="back" onClick={go_back}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                )}
                <div className="title">{props.title}</div>
            </div>

            <div className="right">
                <div className="menu">{props.menu}</div>
                <AuthBubble />
            </div>
        </div>
    );
};
