import { useEffect, useState } from "react";
import { clone, set } from "lodash";
import { NewVpaOrder } from "../../defs/order-model";
import { VertForm } from "../../comps/VertForm";
import { ViewHeader } from "../../comps/ViewHeader";
import { View } from "../../layout/View";
import { SelectAddressView } from "./create-order/SelectAddressView";
import { useNavigate } from "react-router-dom";
import { SelectPackageView } from "./create-order/SelectPackageView";
import { SelectDatesView } from "./create-order/SelectDatesView";
import { DateRange } from "react-day-picker";
import { SelectNotes } from "./create-order/SelectNotesView";
import { SelectTeamView } from "./create-order/SelectTeamView";
import { Team } from "../../defs/team-model";
import { useUserStore } from "../../stores/user-store";
import { ConfirmOrderView } from "./create-order/ConfirmOrderView";
import { createVpaOrder } from "../../api/order.api";
import { fetchCustomer } from "../../api/customer.api";

// For creating a new vpa order 
export const CreateNewVpaOrderView = () => {
    const setUser = useUserStore((state: any) => state.setUser);
    const user = useUserStore((state: any) => state.user);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [new_order, setNewOrder] = useState<NewVpaOrder>({
        address: "",
        notes: "",
        start_date: null!,
        end_date: null!,
        package_id: 0,
        package: null!
    });

    const update = (field: keyof NewVpaOrder | string, value: any) => {
        let temp = { ...new_order };
        if (field === 'range') {
            temp = {
                ...temp,
                start_date: value.from,
                end_date: value.to,
            };
            setNewOrder(temp);
        } else {
            setNewOrder(set(temp, field, value));
        }
    }

    const create = async () => {
        setLoading(true);

        try {
            const order = await createVpaOrder({
                ...new_order,
            });

            const customer = await fetchCustomer(user.customer.id);
            setUser({ ...user, customer });
            // ReactGA.event({
            //     category: 'Order',
            //     action: 'Created',
            //     label: 'Order created',
            // });

            if (order.id) navigate(`/dash/orders/${order.id}`)
        } catch (e) {
            setError("Failed to create the order.");
        }

        setLoading(false);
    }

    // Check the next previous
    const checkNext = () => {
        switch (page) {
            case 0:
                return new_order?.package_id ? true : false;
            case 1:
                return new_order?.address ? true : false;
            case 2:
                return new_order?.start_date && new_order?.end_date ? true : false;
            case 3:
                return true;
            case 4:
                if (user.customer?.teams?.length === 0) return true;
                if (!new_order?.team) return false;
                return true;
            case 5:
                return false;
            default:
                return false;
        }

    }

    // Check the next previous
    const checkPrev = () => {
        switch (page) {
            case 0:
                return false;
            case 1:
                return true;
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return true;
            case 5:
                return true;
            default:
                return false;
        }
    }

    useEffect(() => {
        console.log(new_order)
    }, [new_order])

    return (
        <View>
            <ViewHeader title={new_order?.package?.name || "Create Order"} />

            <VertForm page={page} setPage={setPage} checkNext={checkNext} checkPrev={checkPrev}>
                <SelectPackageView package={new_order?.package} setPackage={(p) => {
                    update("package", p);
                    setPage(page + 1);
                }} />

                <SelectAddressView
                    next={() => setPage(page + 1)}
                    back={() =>
                        setPage(page - 1)
                    }
                    address={new_order.address}
                    setAddress={(address: string) => update('address', address)}
                />

                <SelectDatesView
                    next={() => setPage(page + 1)}
                    back={() => setPage(page - 1)}
                    max={new_order.package?.max_days || 14}
                    min={new_order.package?.min_days || 1}
                    start_date={new_order.start_date}
                    end_date={new_order.end_date}
                    setRange={(range: any) => update('range', range)}
                />

                <SelectNotes
                    next={() => setPage(page + 1)}
                    back={() => setPage(page - 1)}
                    notes={new_order.notes}
                    setNotes={(v: string) => update('notes', v)}
                />

                {user?.customer?.teams?.length !== 0 && (<SelectTeamView
                    selectTeam={(team: Team) => update('team', team)}
                    selected_team={new_order?.team}
                    next={() => setPage(page + 1)}
                />)}

                <ConfirmOrderView
                    back={() => setPage(page - 1)}
                    create={create}
                    order={new_order}
                    loading={loading}
                    error={error}
                />
            </VertForm>
        </View>
    )
}