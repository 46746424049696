import { PulseLoader } from "react-spinners";
import "../styles/inline-loader.comp.scss";

interface Props {
    color?: string;
    size?: number;
}

const DEF_SIZE = 10;
const DEF_COLOR = "#FFF";

export const InlineLoader = (props: Props) => {
    return (
        <div className="inline-loader">
            <PulseLoader color={props.color ?? DEF_COLOR} size={props.size ?? DEF_SIZE} />
        </div>
    )
}