import { ViewHeader } from "../../../comps/ViewHeader";
import { AgentMarketingForm } from "../../../layout/AmFormLayout";
import { View } from "../../../layout/View";

export const CreateEcoAmView = () => {
    return (
        <View>
            <ViewHeader title="Create Sold Campaign" back />

            <AgentMarketingForm>
                <div className="create-sold-cta">
                    <h1>Let's talk</h1>
                    <p>Book a call to get a tailored Agent Marketing experience.</p>
                </div>

                <div className="calendly">
                    <iframe
                        src="https://calendly.com/bailey-23rdst/client-call-clone?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=2f522f"
                        width="100%"
                        height="100%"
                    ></iframe>
                </div>
            </AgentMarketingForm>
        </View>
    );
}