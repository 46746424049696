import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/upload-image.comp.scss";
import { ReactNode, useRef } from "react"
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

export interface Props {
    children: ReactNode;
    onClick: (e: any) => void;
    label?: string;
    loading?: boolean;
    error?: string;
    disabled?: boolean;
}

export const UploadImage = (props: Props) => {
    const input_ref = useRef<HTMLInputElement>(null!);

    const handle_click = () => {
        if (props.disabled) return;
        input_ref?.current?.click();
    }

    const handle_input = async (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onClick(event);
    }

    return <div className="upload-image-wrapper">
        {props.label && <label>{props.label}</label>}

        <div className="image-to-click" onClick={handle_click}>
            {props.children}
            {!props.disabled && <div className="overlay" >
                <FontAwesomeIcon icon={faEdit} />
            </div>}
        </div>

        <input
            className="hidden-button"
            type="file"
            accept="image/*"
            onChange={handle_input}
            multiple={false}
            ref={input_ref}
        />
    </div>
}