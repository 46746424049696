import "../styles/search-bar.comp.scss";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
    label?: string;
    query: string;
    setQuery: (query: string) => void; // Update query
    onSearch: (query: string) => void; // Called query
    loading: boolean;
    no_search_button?: boolean;
}

export const Serachbar = (props: Props) => {

    // Listen for the enter key press
    const handle_keypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            props.onSearch(props.query);
        }
    }



    return (
        <div className="searchbar-wrapper">

            {/* Input */}
            <div className="search-bar">
                {props.label && <label>{props.label}</label>}
                <input type="text" placeholder="Search" value={props.query} onChange={(e) => props.setQuery(e.target.value)} onKeyDown={handle_keypress} />

                {/* Search button */}
                {!props.no_search_button && <div className="search-button" onClick={() => props.onSearch(props.query)}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>}
            </div>
        </div >
    )
}