import "../styles/package-card.comp.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { add_commas } from "../utils/numbers";
import { PackageOverviewCard } from "./PackageOverviewCard";
import { InlineLoader } from "./InlineLoader";
import { accent_color } from "../defs/color.def";
import { Package, PackageOption } from "../defs/order-model";

export interface Props {
    package?: Package;
    onClick: any;
    sel_package?: Package;
}

export const PackageCard = (props: Props) => {
    const [open, setOpen] = useState(false);

    const handleInfoClick = (e: any) => {
        e.stopPropagation();
        setOpen(true);
    }

    const handleSelect = (id: number) => {
        setOpen(false);
        props.onClick(props.package);
    }
    return (
        <>
            <PackageOverviewCard pack={props.package} options={props?.package?.package_options || []} onSelect={handleSelect} onClose={() => setOpen(false)} open={open} />

            <div className="package-card" onClick={() => props.onClick(props?.package)} id={props?.sel_package?.id === props?.package?.id ? "selected" : ''}>
                <div className="package-card-header-wrapper">
                    <div className="left-package-card-header-wrapper">
                        <div className="package-card-header">
                            {props?.package?.name}
                        </div>
                        <div className="package-card-body">
                            {props?.package?.desc}
                        </div>
                    </div>
                    <div className="package-cost">
                        ${add_commas(props?.package?.price || 1 / 100)}
                    </div>
                </div>
                <hr />
                <div className="package-card-bottom">
                    <div className="package-card-impressions">
                        <label>Views</label>
                        <div className="package-card-impression">
                            {/* <FontAwesomeIcon icon={faEye} /> */}
                            {add_commas(props?.package?.impressions || 0)} +
                        </div>
                    </div>
                    <div className="package-card-options">
                        {props?.package?.package_options?.map((option, index) => (
                            <PackageCardOption key={index} option={option} />
                        ))}

                    </div>

                    {/* Information button */}
                    <div className="info-btn" onClick={handleInfoClick}>
                        <FontAwesomeIcon icon={faInfo} />
                    </div>
                </div>

            </div >
        </>
    );
}

export const PackageCardOption = ({
    option
}: { option: PackageOption }) => {

    return (
        <div className="package-option">
            {option.checked ? (
                <FontAwesomeIcon icon={faCheck} />
            ) : (
                <FontAwesomeIcon icon={faTimes} />
            )}
            <div>{option.name}</div>
        </div>
    )
}