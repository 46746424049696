import '../styles/address.comp.scss';
import { useEffect, useState } from 'react';
import { debounce } from '../utils/network';
import { fetchSuggestions } from '../api/places.api';

interface Props {
    label?: string;
    value: string;
    set: boolean;
    onChange: any;
    onSet: any;
}

export const AddressInput = (props: Props) => {
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * This function gets the suggestions
     */
    const getSuggestions = async () => {
        console.log('Getting suggestions');

        if (!props.value) return;

        setLoading(true);

        try {
            const suggestions = await fetchSuggestions(props.value);
            setSuggestions(suggestions?.splice(0, 4));
        } catch (err) {
            setSuggestions([]);
        }

        setLoading(false);
    };

    const debouncedSearch = debounce(getSuggestions, 500);

    // When the value changes, lookup suggestions
    useEffect(() => {
        debouncedSearch(props.value);
    }, [props.value]);

    useEffect(() => {
        setVisible(false);
    }, [props.set]);

    return (
        <div className="address-wrapper">
            {props.label && <label>{props.label}</label>}

            <input
                placeholder="Enter address..."
                value={props.value}
                onChange={(v: any) => props.onChange(v.target.value)}
            />

            {/* Suggestions shown here */}
            {suggestions.length > 0 && !props.set && (
                <div className="suggestions">
                    {suggestions.map((sug: string, i: number) => (
                        <div
                            className="suggestion"
                            key={i}
                            onClick={() => props.onSet(sug)}
                        >
                            {sug}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
