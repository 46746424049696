import "../../../styles/select-team.create-order.scss";
import { Button } from "../../../comps/Button";
import { Team } from "../../../defs/team-model";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../stores/user-store";
import { fetchCustomer } from "../../../api/customer.api";
import { InlineLoader } from "../../../comps/InlineLoader";
import { accent_color } from "../../../defs/color.def";

export interface Props {
    selected_team?: Team | undefined;
    selectTeam: (team: Team) => void;
    next: () => void;
}

export const SelectTeamView = (props: Props) => {
    const user = useUserStore((state: any) => state.user);
    const [teams, setTeams] = useState<Team[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const load_teams = async () => {
        if (!user) return;

        console.log("Got the user: ", user);

        setLoading(true);
        try {
            const customer = await fetchCustomer(user.customer.id);

            console.log('Got the customer: ', customer);

            setTeams(customer.teams);
        } catch (err) {
            setError("Failed to load the customers teams...");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (user) load_teams();
    }, [user]);

    return (
        <div className="page extra-page">
            <div className="extra-page-inner">
                <div className="left">
                    <h1>Who is this order for?</h1>
                    <p>Select which team this campaign is for.<br /> If you don't see the right team you can navigate to the Organisation View to create a new one and assign other Agents.</p>

                    {props.selected_team && (
                        <p><b>Selected Team: </b>{props.selected_team.name}</p>
                    )}

                    {props.selected_team && (
                        <div className="confirm-button confirm-button-desktop">
                            <Button
                                style={{
                                    maxWidth: '200px',
                                }} onClick={() => props.next()}>
                                Continue</Button>
                        </div>)}
                </div>


                <div className="order-deatils-form team-order-form">
                    <label>Select a Team</label>
                    <div className="team-list">
                        {loading && (
                            <InlineLoader color={accent_color} />
                        )}

                        {teams?.map((team: any, index) => (
                            <div key={index} className="team" onClick={() => props.selectTeam(team.team)}>
                                <div className="team-name">{team?.team?.name}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="confirm-button confirm-button-mobile">
                    <Button
                        style={{
                            maxWidth: '200px',
                        }}
                        onClick={() => props.next()}>
                        Continue</Button>
                </div>
            </div>
        </div>);
}