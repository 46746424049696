import "../styles/shutter-list.comp.scss";
import { InlineLoader } from "./InlineLoader";

export interface Props {
    data: any[];
    renderItem: (item: any) => React.ReactNode;
    loading: boolean;
}

export const ShutterList = (props: Props) => {
    return (
        <div className="shutter-list-inner">
            {props.loading && <div className="shutter-list-loading">
                <InlineLoader color="#404e3d" />
            </div>}

            {props.data.map((item, index) => {
                return (
                    <div className="shutter-list-item" key={index}>
                        {props.renderItem(item)}
                    </div>
                )
            })}
        </div>
    )
}