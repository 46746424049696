import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import '../styles/date-picker.comp.scss';

export interface Props {
    date: Date | undefined;
    end_date: Date | undefined;
    setRange: any; // Set the date ranges
    min: number;
    max: number;
}

// Date Picker component
export const DatePicker = (props: Props) => {
    const [range, setRange] = useState<DateRange | undefined>({
        from: props.date || undefined,
        to: props.end_date || undefined,
    });

    const handleSelect = (newRange: DateRange) => {
        setRange(newRange);
    };

    useEffect(() => {
        if (range) props.setRange(range);
    }, [range]);

    useEffect(() => {
        // Check if they are different from range
        if (props.date && props.end_date) {
            if (
                props.date.getTime() !== range?.from?.getTime() ||
                props.end_date.getTime() !== range?.to?.getTime()
            ) {
                setRange({
                    from: props.date,
                    to: props.end_date,
                });
            }
        }
    }, [props.date, props.end_date]);

    let footer = <p>Please select dates.</p>;

    if (props.date) {
        footer = (
            <>
            </>
        );
    }

    return (
        <div className="date-picker">
            <DayPicker
                mode="range"
                selected={range}
                defaultMonth={new Date()}
                onSelect={handleSelect as any}
                fromDate={new Date()}
                footer={footer}
                max={props.max}
                min={props.min <= 1 ? 2 : props.min}
            />
        </div>
    );
};
