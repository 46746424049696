import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/vert-form.comp.scss";
import { ReactNode } from "react";
import { faChevronCircleUp, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export interface Props {
    page: number;
    setPage: (p: number) => void;
    children: ReactNode;
    checkNext: () => boolean;
    checkPrev: () => boolean;
}

/**
 * This component loads a vertical sliding form, pass in the pages as children
 */
export const VertForm = (props: Props) => {


    return (
        <div className="vert-form">
            {/* Navigation bar */}
            <div className="vert-form-nav">
                {props.checkPrev() ? (
                    <div className="vert-form-nav-btn" onClick={() => props.setPage(props.page - 1)}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </div>) : (<div></div>)}
                {props.checkNext() ? (
                    <div className="vert-form-nav-btn" onClick={() => props.setPage(props.page + 1)}>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>) : (<div></div>
                )}
            </div>


            {/* Pages */}
            <div className="pages" style={{
                top: `calc((100%) * -${props.page})`
            }}>
                {props.children}
            </div>
        </div>
    );
}