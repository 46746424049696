import React from 'react';
import ReactDOM from 'react-dom/client';
import { View } from './layout/View';
import { RootRouter } from './routing/root-router';
import { AlertProvider } from './hooks/alert-provider';
import { ModalProvider } from './hooks/modal-provider';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://c06dae779fb2a84950a08695b944311d@o4506035971883008.ingest.sentry.io/4506280608399360",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/edge\.23rdst\.com\.au/],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AlertProvider>
      <ModalProvider>
        <RootRouter />
      </ModalProvider>
    </AlertProvider>
  </React.StrictMode>
);