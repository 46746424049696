import "../styles/add-team.modal.scss";
import { useState } from "react";
import { Agency } from "../defs/agency-model";
import { ModalLayout } from "./ModalLayout";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { addTeam } from "../api/team.api";
import { Button } from "./Button";
import { useUserStore } from "../stores/user-store";

interface Props {
    agencies: Agency[];
    onAdd: () => void;
}

export const AddTeamModal = (props: Props) => {
    const user = useUserStore((state: any) => state.user);
    const [name, setName] = useState("");
    const [selected_agency, setSelectedAgency] = useState<Agency | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // Create the new team
    const add = async () => {
        if (!selected_agency) return setError("Please select an agency.");
        setLoading(true);

        try {
            await addTeam({
                name,
                agency_id: selected_agency.id,
                member: user.id
            });
        } catch (err) {
            setError("Failed to add the team...");
        }

        setLoading(false);
    }

    // handle selecting the agency
    const selectAgency = (agency: Agency) => {
        // If it is already selected, unselect it
        if (selected_agency?.id === agency.id) setSelectedAgency(null!);
        else setSelectedAgency(agency);
    }


    return (
        <ModalLayout>
            <label>Select Agency</label>
            <div className="agency-list-select">
                {props.agencies.map((agency) => (
                    <div
                        key={agency.id}
                        className={`agency-list-item ${agency.id === selected_agency?.id ? "selected" : ""}`}
                        onClick={() => selectAgency(agency)}
                    >
                        {agency.name}
                    </div>
                ))}
            </div>

            <FormLayout>
                <Input label="Team Name" value={name} onChange={setName} />
                <br />
                <Button disabled={!(Boolean(selected_agency) && name)} onClick={add} loading={loading}>Create Team!</Button>
            </FormLayout>

        </ModalLayout>
    )
}