import "../styles/shutter-order-list-item.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Order } from "../defs/order-model";
import { faEdit, faEye, faFileInvoice, faSignHanging } from "@fortawesome/free-solid-svg-icons";
import { convert_type } from "../api/order.api";

interface Props {
    open: boolean;
    order: Order;
    onClick: (order: Order) => void;
    onEdit?: (order: Order) => void;
    selected?: boolean;
}


export const ShutterOrderListItem = (props: Props) => {
    return (
        <div className="team-list-item" onClick={() => props.onClick(props.order)} id={props.selected ? "selected" : "unselcted"}>
            <div className="order-logo">
                <FontAwesomeIcon icon={props.order.order_type === "VPA" ? faFileInvoice : faSignHanging} />
            </div>
            <div className="team-list-item-right">
                {props.open && (
                    <div className="team-list-item-name">
                        {props.order?.vpa_order?.address || `Agent Marketing ${convert_type(props?.order?.am_order?.type)}`}
                    </div>)}
                {props.open && (
                    <div className="team-list-item-actions">
                        <div className="team-list-item-edit">
                            <FontAwesomeIcon icon={faEye} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

}