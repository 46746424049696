import "../styles/react-select-wrapper.scss";
import ReactSelect from "react-select"

interface Props {
    label?: string;
    options: any[];
    value: any;
    onChange: (value: any) => void;
    isMulti?: boolean;
    isClearable?: boolean;
}

export const ReactSelectWrapper = (props: Props) => {
    return (
        <div className="react-select-wrapper">
            {props.label && <label>{props.label}</label>}
            <ReactSelect className="rs" classNamePrefix="rs" options={props.options} value={props.value} isMulti={props.isMulti} isClearable onChange={props.onChange} />
        </div>
    )
}