
import { ReactNode } from "react";
import "../styles/container.layout.scss";

export const Container = ({ children, id }: { children: ReactNode, id?: string }) => {
    return (
        <div className="container" id={id}>
            {children}
        </div>
    )
}