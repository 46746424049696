import { Button } from '../comps/Button';
import '../styles/alert.provider.scss';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';

// Define the Alert type
export interface Alert {
    id?: string;
    heading?: string;
    message: string;
    on_confirm?: any;
    on_close?: any;
}

// Define the context interface
export interface AlertContext {
    alerts: Alert[];
    create: (alert: Alert) => string | null;
}

// Create the context
const AlertContext = createContext<AlertContext>(null!);

// Crate the provider
export const AlertProvider = ({ children }: { children: ReactNode }) => {
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const create = (alert: Alert) => {
        alert.id = Math.random().toString(36).substring(2, 9);
        setAlerts([...alerts, alert]);

        return alert.id || null;
    };

    const close = (id: string) => {
        setAlerts([...alerts.filter((alert: Alert) => alert.id === id)]);
    };

    useEffect(() => {
        if (alerts.length) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [alerts]);

    return (
        <AlertContext.Provider
            value={{
                alerts,
                create,
            }}
        >
            {children}
            <div className="alert-container">
                <div
                    className="alert-bg"
                    style={{
                        display: !alerts.length ? 'none' : 'unset',
                    }}
                />

                <div className="alerts">
                    {alerts.map((alert: Alert) => (
                        <ConfirmAlert alert={alert} close={close} />
                    ))}
                </div>
            </div>
        </AlertContext.Provider>
    );
};

/**
 * Actual Alert component
 */
export const ConfirmAlert = (props: { alert: Alert; close: any }) => {
    return (
        <div className="alert">
            <div className="inner">
                {/* Heading */}
                {props.alert.heading && <h2>{props.alert.heading}</h2>}

                {/* Body  */}
                <p>{props.alert.message}</p>

                {/* Options */}
                <div className="buttons">
                    <Button
                        onClick={() => {
                            props.alert.on_close && props.alert.on_close();
                            props.close();
                        }}
                    >
                        Close
                    </Button>

                    {props.alert.on_confirm && (
                        <Button
                            onClick={() => {
                                props.alert.on_confirm();
                                props.close();
                            }}
                        >
                            Confirm
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

// Define the use hook
export const useAlerts = () => {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error('useAlerts must be inside of a provider.');
    }
    return context;
};
