import { useEffect, useState } from "react";
import "../styles/campaign-report.comp.scss";
import { faEye, faHeart, faMousePointer, faPeopleGroup, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { get_meta_demos, get_meta_stats, get_meta_totals } from "../api/meta.api";
import { AreaChartCard } from "./AreaChartCard";
import { BarChartCard } from "./BarChartCard";
import { MetricCard } from "./MetricCardView";

interface Props {
    facebook_ad_id: string; // Facebook ad id
    report_id: string; // Report id
    id: number; // VPA id
    header_img?: string;
    address: string;
    agency_logo?: string;
    agency_name: string;
    for_agent_marketing?: boolean;
    agent_name?: string;
}

export const CampaignReport = (props: Props) => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [impressions, setImpressions] = useState<any[]>([]);
    const [reach, setReach] = useState<any[]>([]);
    const [demos, setDemos] = useState<any[]>([]);
    const [total_imp, setTotalImp] = useState<number>(null!);
    const [total_reach, setTotalReach] = useState<number>(null!);
    const [total_clicks, setTotalClicks] = useState<number>(null!);
    const [total_eng, setTotalEng] = useState<number>(null!);

    const load = async () => {
        if (loading) return; // Prevent double loading.

        setLoading(false);
        if (error) setError("");

        // if (props.for_agent_marketing) {
        try {
            // Get the stats
            const stats = await get_meta_stats(props.facebook_ad_id);
            setImpressions(stats.map((d: any) => ({ impressions: Number(d?.impressions), date: d?.date_start })));
            setReach(stats.map((d: any) => ({ reach: Number(d?.reach), date: d?.date_start })));

            // Get the totals
            const totals = await get_meta_totals(props.facebook_ad_id);
            setTotalImp(totals?.impressions);
            setTotalReach(totals?.reach);
            setTotalEng(totals?.engagement);
            setTotalClicks(totals?.clicks);

            const demos = await get_meta_demos(props.facebook_ad_id);
            setDemos(demos);
        } catch (err) {
            console.error("Failed to get the facebook data: ", err);
            setError("Failed to get the report data.");
        }

        setLoading(false);
    }

    useEffect(() => {
        load();
    }, [props.id])

    return (
        <div className="report-wrapper">
            {/* Top header component -> Grab the listings header image */}
            <div className="report-header">
                <div className="report-header-image" style={{
                    backgroundImage: `url('${props.header_img}')`
                }} />
                <div className="report-header-header">
                    {props.address}
                    <div className="report-header-agency">
                        <div className="report-header-agency-name">
                            {props.agency_name}

                        </div>
                        {props.agent_name && <div className="report-header-agent-name">
                            {props.agent_name}
                        </div>}
                    </div>
                    <div className="report-header-subheader">
                        <p>Campaign Report</p>
                        <p>{format(new Date(), "dd/MM/yyyy")}</p>
                    </div>
                </div>
            </div>


            {/* Impressions */}
            <div className="chart-area-grid">
                <AreaChartCard title="Impressions" icon={faEye} total={total_imp} data={impressions} />
                <AreaChartCard title="Reach" icon={faUserGroup} total={total_reach} data={reach} />
            </div>

            {/* Impressions */}
            <div className="facebook-metrics">
                <MetricCard metric={total_imp} icon={faEye} label="Total Impressions" tooltip="The number of times your listing ad was displayed to users. It represents the potential reach or exposure of your ad to the target audience viewing the listings." />
                <MetricCard metric={total_clicks} icon={faMousePointer} label="Total Clicks" tooltip=" The number of times users interacted with your listing ad by clicking on it. It measures the level of engagement and interest generated by your ad, indicating how many people found it compelling enough to click for more information or to visit the listing." />
                <MetricCard metric={total_reach} icon={faUserGroup} label="Total Reach" tooltip="The total number of unique users who have seen your listing ad. It shows the size of your ad's audience and represents the number of individuals reached by your campaign within the listing platform." />
                <MetricCard metric={total_eng} icon={faHeart} label="Total Engagement" tooltip="The level of interaction and involvement generated by your listing ad. It includes actions like clicks, inquiries, bookmarks, or any other form of user engagement with the listing. Engagement metrics provide insights into how well your ad captures the attention and interest of potential leads." />
            </div>
            {/* Demographics */}
            <BarChartCard data={demos} icon={faPeopleGroup} total={total_reach} title={"Demographics"} />

            <div className="company-logo-section">
                <div className="company-logo">
                    {props.agency_logo ? (
                        <img src={props.agency_logo} />
                    ) : (
                        <h3>{props.agency_name}</h3>
                    )}
                </div>
            </div>

            <div id="bottom-section">
                <p>
                    <strong>Impressions:</strong> Impressions refer to the number of times your ad was displayed on users' screens. It represents the potential reach of your campaign, indicating how many users had the opportunity to see your ad.
                </p>
                <p>
                    <strong>Total Clicks:</strong> Total Clicks represents the cumulative number of times users clicked on your ad. It measures the level of engagement and interest generated by your campaign.
                </p>
                <p>
                    <strong>Total Reach:</strong> Total Reach signifies the total number of unique users who saw your ad at least once. It provides insights into the size of your ad's audience and the potential exposure of your campaign.
                </p>
                <p>
                    <strong>Total Engagement:</strong> Total Engagement measures the overall interaction with your ad. It includes various actions taken by users such as clicks, likes, comments, and shares. It indicates the level of user involvement and the impact of your campaign on the audience.
                </p>
                <p>
                    <strong>Disclaimer:</strong> The metrics provided in this report are based on available data and may be subject to limitations and discrepancies. Please consider additional factors and data sources when evaluating the performance of your campaign.
                </p>
            </div>

            {!loading && (<div id="loaded" />)}

        </div>
    )

}
