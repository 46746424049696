import "../styles/button.comp.scss";
import { ReactNode } from "react";
import { InlineLoader } from "./InlineLoader";

interface Props {
    disabled?: boolean;
    onClick?: () => void;
    children: ReactNode;
    loading?: boolean;
    style?: any;
}

export const Button = (props: Props) => {
    return (
        <button className="button" disabled={props.disabled} onClick={props.onClick} style={props.style}>
            {props.loading ? <InlineLoader color="#fff" /> : props.children}
        </button>
    )
}