import { useEffect, useState } from 'react';
import '../styles/map.comp.scss';
import { InlineLoader } from './InlineLoader';
import { accent_color } from '../defs/color.def';

// Define the props
export interface Props {
    // address: string; // Marker address -> Might need to be coordinates, would be cool to have a simplier interface though
    coords?: { lat: number; lng: number }; // Marker coordinates
    zoom: number; // Zoom level
    radius?: boolean; // Show radius
    custom_marker?: string;
}

/// This is the Google Maps component
export const Map = (props: Props) => {
    const [map, setMap] = useState<any>(null!);
    const [marker, setMarker] = useState<any>(null!);
    const [radius, setRadius] = useState<any>(null!);

    const initMap = () => {
        try {
            const map = new (window as any).google.maps.Map(
                document.getElementById('map-hook')!,
                {
                    center: { lat: -23.033333, lng: 132.166667 },
                    zoom: 3,
                    mapId: '797fe9182648720f',
                    disableDefaultUI: true,
                }
            );

            // Set the map
            setMap(map);

        } catch (err) {
            console.error("Failed to init the map: ", err)
        }
    };

    // Watch the coords
    useEffect(() => {
        if (map && props.coords) {
            // Set the center
            map.setCenter(props.coords);

            if (marker) marker.setMap(null);
            if (radius) radius.setMap(null);

            if (props.radius) {
                var temp_radius = new (window as any).google.maps.Circle({
                    strokeColor: '#404e3d',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#404e3d47',
                    fillOpacity: 0.35,
                    map: map,
                    center: props.coords,
                    radius: 1000 * 5,
                });

                map.fitBounds(temp_radius.getBounds());
                setRadius(temp_radius);
            }

            // Set the zoom
            if (map) map?.setZoom(15);

            // Try to get the 

            // Add google maps marker
            var temp_marker = new (window as any).google.maps.Marker({
                position: props.coords,
                map: map,
                icon: {
                    url: '/img/location-dot-solid.svg',
                    scaledSize: {
                        width: 35,
                        height: 35
                    }
                },
            });

            // Save the marker
            setMarker(temp_marker);
        }
    }, [props.coords]);

    useEffect(() => {
        if ((window as any)?.google) {
            // window.onload = initMap;
            initMap();
        }
    }, [(window as any)?.google]);

    return (
        <div className="map-wrapper">
            {/* Show that the map is loading */}
            <div id="map-hook" />

            {/* If no map and no coords */}
            {!props.coords ||
                (!map && (
                    <div className="map-loading">
                        <InlineLoader color={accent_color} />
                    </div>
                ))}
        </div>
    );
};

// Little helper function for converting coords to the coords for the map
export const convertCords = (dirty: any) => {
    return {
        lat: dirty?.lat | dirty?.[0],
        lng: dirty?.long | dirty?.[1],
    };
};