import "../styles/side-panel.comp.scss";
import { useWidth } from "../hooks/use-width";
import { ReactNode, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { Logo } from "./Logo";
import { useUserStore } from "../stores/user-store";
import { useNavigate } from "react-router-dom";

export const SidePanel = ({
    children
}: { children: ReactNode }) => {
    const width = useWidth();
    const [open, setOpen] = useState(width > 1000 ? true : false);

    // If width is less than 800px render the other side panel 
    if (width <= 800) {
        return <MobileSidePanel children={children} />
    }

    return (
        <div className="side-panel-wrapper" id={open ? 'open' : 'closed'}>
            <motion.div
                animate={{ width: open ? 300 : 66 }}
                transition={{ duration: 0.2 }}
                className="side-panel-hidden"
            />
            <motion.div
                animate={{ width: open ? 240 : 50 }}
                transition={{ duration: 0.2 }}
                className="side-panel"
            >
                <div className="side-panel-inner-wrapper">
                    <motion.div
                        className="inner"
                        animate={{ width: open ? 240 : 50 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="side-panel-header">
                            <Logo alt />
                            <motion.div
                                className="action-btn"
                                onClick={() => setOpen(!open)}
                            >
                                <FontAwesomeIcon
                                    icon={faBars}
                                    onClick={() => setOpen(!open)}
                                />
                            </motion.div>
                        </div>
                        <div className="main-content">{children}</div>

                        {/* use this for bottom content */}
                        <div className="secondary-content">
                        </div>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
}

const MobileSidePanel = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);

    // Sign out the user 
    const sign_out = () => {
        // Remove the user from the store
        setUser({ user: null });
        // Navigate to the login page
        navigate('/login');
    }

    return (
        <div className="m-side-panel-wrapper">
            <div className="m-side-panel-btn" onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className="m-side-panel" id={open ? "open" : "closed"} onClick={() => setOpen(false)}>
                <div className="m-side-panel-header">
                    <div className="logo-wrapper">
                        <Logo alt />
                    </div>
                    <div className="m-side-panel-header-btn" onClick={() => setOpen(false)}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>

                <div className="m-main-content">{children}</div>

                <div className="m-auth">
                    <div className="user-profile">
                        <div className="dp-wrapper">
                            <div
                                className="dp"
                                style={{
                                    backgroundImage: `url('${user?.dp
                                        ? user.dp
                                        : `https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=404e3d&color=fff&size=128`
                                        }')`,
                                }}
                            />
                        </div>
                        <div className="details">
                            <div className="name">
                                {user?.first_name} {user?.last_name}
                            </div>
                        </div>
                    </div>


                    <div className="logout" onClick={() => sign_out()}>
                        <FontAwesomeIcon icon={faSignOut} />
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )

}