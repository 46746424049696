import "../styles/package-overview-card.comp.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { add_commas } from "../utils/numbers";
import { Package, PackageOption } from "../defs/order-model";
import { PackageCardOption } from "./PackageCardOption";
import { Button } from "./Button";

interface Props {
    pack?: Package;
    options: PackageOption[];
    onSelect: (id: number) => void;
    onClose: () => void;
    open: boolean;
}

export const PackageOverviewCard = (props: Props) => {
    const handleSelect = () => {
        if (!props.pack) return;
        props.onSelect(props.pack.id);
    }

    if (props.open) {
        return (
            <div className="package-overview-card-wrapper">
                <div className="package-overview-card-bg" />
                <div className="package-overview-card">

                    {/* Close button  */}
                    <div className="package-overview-close-btn" onClick={props.onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>

                    {/* Package details */}
                    <div className="package-details">
                        <h2>{props?.pack?.name}</h2>
                        <p>{props?.pack?.desc}</p>

                        <div className="package-field-row">
                            <div className="package-field">
                                <label>Views</label>
                                <div className="value">{add_commas(Number(props?.pack?.impressions))}</div>
                            </div>
                            <div className="package-field">
                                <label>Price</label>
                                <div className="value">${add_commas(Number(props?.pack?.price) / 100)}</div>
                            </div>
                        </div>

                        {/* Feature list */}
                        <div className="package-card-options-wrapper">
                            <label>Included</label>
                            <div className="package-card-options">
                                {props?.options?.map((option, index) => (
                                    <PackageCardOption key={index} option={option} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-wrapper">
                        <Button onClick={handleSelect}>Select Package</Button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}