import "../styles/slide-show-header.scss";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

interface Props {
    images: string[]
    interval?: number;
}

export const SlideshowHeader = (props: Props) => {
    const interval = props.interval || 300;
    const [pos, setPos] = useState(0);

    useEffect(() => {
        // Clear the existing timer when the position changes
        const timer = setTimeout(() => {
            setPos((pos + 1) % props.images.length);
        }, 5000);

        return () => {
            clearTimeout(timer); // Clear the timer when the component unmounts or when pos changes
        };
    }, [pos, props.images.length]);



    return (
        <div className="slide-show-header">
            <div className="image-container-wrapper">
                <motion.div className="image-container"
                    style={{
                        width: (props.images.length * 100) + "%"

                    }}
                    initial={{
                        x: 0,
                    }}
                    animate={{
                        x: -pos * (100 / props.images.length) + "%"
                    }}
                    transition={{
                        duration: 0.35,
                        ease: "easeInOut"
                    }}
                >
                    {props.images.map((image, index) => (
                        <div className="image" key={index} style={{
                            backgroundImage: `url('${image}')`
                        }} />
                    ))}
                </motion.div>
            </div>

            <div className="dots">
                {props.images.map((image, index) => (
                    <div className="dot-wrapper" onClick={() => setPos(index)} key={index}>
                        <div className={`dot ${index === pos ? "active" : ""}`} />
                    </div>
                ))}
            </div>
        </div>
    )
}
