import "../styles/shutter-team-list-item.comp.scss";
import { Team } from "../defs/team-model";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
    open: boolean;
    team: Team;
    onClick: (team: Team) => void;
    onEdit?: (team: Team) => void;
    selected?: boolean;
}

export const ShutterTeamListItem = (props: Props) => {
    return (
        <div className="team-list-item" onClick={() => props.onClick(props.team)} id={props.selected ? "selected" : "unselected"}>
            <div className="team-list-item-logo" style={{
                backgroundImage: `url(${props.team?.lead_customer?.dp})`
            }} />
            <div className="team-list-item-right">
                {props.open && (
                    <div className="team-list-item-name">
                        {props.team.name}
                    </div>)}
                {props.open && (
                    <div className="team-list-item-edit" onClick={(e) => {
                        e.stopPropagation();
                        props.onEdit && props.onEdit(props.team);
                    }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </div>)}

            </div>
        </div>
    );
}