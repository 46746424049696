
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Customer } from "../defs/customer-model";
import { faEdit, faFileInvoice } from "@fortawesome/free-solid-svg-icons";

export interface Props {
    open: boolean;
    customer: Customer;
    onClick: (customer: Customer) => void;
    onEdit?: (customer: Customer) => void;
    selected?: boolean;
}

export const ShuttersCustomerListItem = (props: Props) => {
    return (
        <div className="team-list-item" onClick={() => props.onClick(props.customer)} id={props.selected ? "selected" : "unselcted"}>
            <div className="team-list-item-logo" style={{
                backgroundImage: `url(${props.customer?.dp})`
            }} />
            <div className="team-list-item-right">
                {props.open && (
                    <div className="team-list-item-name">
                        {props.customer.first_name} {props.customer.last_name}
                    </div>)}
                {props.open && (
                    <div className="team-list-item-icons">
                        <div className="team-list-item-edit" onClick={(e) => {
                            e.stopPropagation();
                            props.onEdit && props.onEdit(props.customer);
                        }}>
                            <FontAwesomeIcon icon={faEdit} />
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}