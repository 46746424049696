import '../styles/image-viewer.comp.scss'
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from 'react-dom';

export interface Props {
    image: string;
    visable: boolean;
    onClose: () => void;
}


export const ImageViewer = (props: Props) => {
    const handle_close = (e: any) => {
        e.stopPropagation();
        props.onClose();
    }

    return (
        <div className={`image-viewer-wrapper`}>
            <div className={`image-viewer-image ${props.visable ? "open" : "close"}`}>
                <img src={props.image} />
                <div className="image-viewer-close-btn" onClick={handle_close}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
        </div>
    );
}
