import qs from "qs"
import { Agency } from "../defs/agency-model"
import { Filter } from "../defs/corporation-model"
import { Customer } from "../defs/customer-model"
import { Team } from "../defs/team-model"
import { client } from "./api.config"

// Get all agencies belonging to a corporation
export const fetchAgenciesBelongingToCorporation = async (corporationId: number): Promise<Agency[]> => {
    const resp = await client().get(`/corporation/${corporationId}/agency`)
    return resp.data;
}

// Get all the teams for the corp view, filter by agency
export const fetchTeamsForCorpView = async (corporation_id: number, filters?: Filter[]): Promise<Team[]> => {
    // Map the agency ids from the filters
    const agency_ids = filters?.filter(filter => filter.name === 'agency').map(filter => filter.value);

    const resp = await client().get(`/corporation/${corporation_id}/team`, {
        params: {
            agencies: agency_ids
        }
    })
    return resp.data;
}


// Get all the customers for a corporation, optional agencies, and teams filter
export const fetchCustomersForCorpView = async (corporation_id: number, filters?: Filter[]): Promise<Customer[]> => {
    const agency_ids = filters?.filter(filter => filter.name === 'agency').map(filter => filter.value);
    const team_ids = filters?.filter(filter => filter.name === 'team').map(filter => filter.value);

    const resp = await client().get(`/corporation/${corporation_id}/customer`, {
        params: {
            agencies: agency_ids,
            teams: team_ids
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    });

    return resp.data;
}

// Get all the orders for a corporation, optional agencies, and teams, and customer filters
export const fetchOrdersForCorpView = async (corporation_id: number, filters?: Filter[]): Promise<any[]> => {
    const agency_ids = filters?.filter(filter => filter.name === 'agency').map(filter => filter.value);
    const team_ids = filters?.filter(filter => filter.name === 'team').map(filter => filter.value);
    const customer_ids = filters?.filter(filter => filter.name === 'customer').map(filter => filter.value);

    const resp = await client().get(`/corporation/${corporation_id}/order`, {
        params: {
            agencies: agency_ids,
            teams: team_ids,
            customers: customer_ids
        }
    });

    return resp.data;
}
