import "../../styles/reset-password.view.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useUserStore } from "../../stores/user-store";
import { resetPassword } from "../../api/customer.api";
import { Logo } from "../../comps/Logo";
import { FormLayout } from "../../layout/FormLayout";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";


export const ResetPasswordView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const user = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const check_passwords = () => {
        if (password !== confirm_password) {
            setError("Passwords do not match.");
            return false;
        }

        return true;
    }

    const set_password = async () => {
        if (!id) return;
        setLoading(true);
        setError("");

        if (!check_passwords()) {
            setLoading(false);
            setError("Passwords don't match!")
            return;
        }

        try {
            const user = await resetPassword(id, password);
            if (!user) {
                setError("Failed to reset the password.");
                return;
            }

            setUser(user);
        } catch (err) {
            console.error("Failed to reset the password: ", err);
        }

        setLoading(false);
        setPassword("");
        setConfirmPassword("");
    }


    useEffect(() => {
        // If we logged in
        if (user) {
            navigate("/dash/home");
        }
    }, [user]);

    return (
        <div className="reset-password-view">
            <div className="reset-password-view-form-con">
                <Logo />
                <FormLayout>
                    <Input type="password" label="New Password" value={password} onChange={setPassword} disabled={loading} />
                    <Input type="password" label="Confirm Password" value={confirm_password} onChange={setConfirmPassword} disabled={loading} />
                    <Button onClick={set_password} loading={loading}>Reset Password</Button>
                    {error && <div className="error">{error}</div>}
                </FormLayout>
            </div>
        </div>
    )
}