
/**
 * This function adds the commas to a number to make it easier to read
 */
export const add_commas = (value: number | string) => {
    value = String(value);

    // Start from the end of the number and every time we reach 3 digits, add a comma
    let str = value.toString();
    let new_str = "";
    let count = 0;

    for (let i = str.length - 1; i >= 0; i--) {
        count++;

        new_str = str[i] + new_str;

        if (count % 3 === 0 && i !== 0) {
            new_str = "," + new_str;
        }
    }

    return new_str;
}