
import { useState } from "react"
import { debounce } from "lodash"
import { useNavigate } from "react-router-dom"
import { useUserStore } from "../../../stores/user-store";
import { View } from "../../../layout/View";
import { ViewHeader } from "../../../comps/ViewHeader";
import { AgentMarketingForm } from "../../../layout/AmFormLayout";
import { AddressInput } from "../../../comps/AddressInput";
import { DateInput } from "../../../comps/DateInput";
import { Textarea } from "../../../comps/TextArea";
import { add_commas } from "../../../utils/numbers";
import { Slider } from "../../../comps/Slider";
import { Button } from "../../../comps/Button";
import { UploadImageMini } from "../../../comps/UploadMiniImage";
import { createAmOrder } from "../../../api/order.api";
import { AmType } from "../../../defs/order-model";
import { Option, Select } from "../../../comps/Select";
import { Team } from "../../../defs/team-model";

export const CreateTestAmView = () => {
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [date, setDate] = useState(new Date());
    const [images, setImages] = useState<string[]>([]);
    const [notes, setNotes] = useState("");
    const [budget, setBudget] = useState(750);
    const [team, setTeam] = useState<number>(null!);

    // Handle a new image
    const handle_images = (image: string) => {
        setImages([...images, image]);
    }

    // Handle removing an image
    const handle_images_remove = (image: string) => {
        const new_images = images.filter((i: string) => i !== image);
        setImages([...new_images]);
    }

    // Handle the budget being changed, need to debounce 
    const handle_budget = debounce((newValue) => {
        setBudget(newValue);
    }, 10); // Adjust the debounce delay as needed

    // Handle creating the order
    const create_order = async () => {
        setLoading(true);
        setError("")

        try {
            const am = await createAmOrder({
                type: AmType.TEST,
                date: date,
                budget: budget,
                notes: notes,
                media: images,
                team_id: team
            })

            navigate(`/dash/agent-marketing/${am.id}`);
        } catch (err) {
            console.error("Failed to create the order: ", err);
            setError("Failed to create the order.");
        }

        setLoading(false);
    }

    // Map the teams to the select options
    const team_options = () => {
        return user.customer?.teams?.map((team: any) => ({
            value: team?.team?.id,
            label: team?.team?.name,
        })) || [];
    }

    // Find the selected value
    const team_selected = () => {
        return team_options().find((o: Option) => o.value === team);
    }

    return (
        <View>
            <ViewHeader title="Create Sold Campaign" back />

            <AgentMarketingForm>
                <div className="create-sold-cta">
                    <h1>Almost there...</h1>
                    <p>We just need a few more details from you, then you're done!</p>
                </div>

                {user?.customer?.teams?.length > 0 && (
                    <Select label="Team" value={team_selected()} onChange={(o: Option) => setTeam(o.value)} options={team_options()} />
                )}

                <DateInput label="Scheduled Date" value={date} onChange={setDate} />

                <Textarea label="Additional Notes" value={notes} onChange={setNotes} rows={6} />

                <div className="budget">
                    <label>Budget</label>
                    <h3>${add_commas(budget)} / {add_commas(roundDownToNearestThousand(budget * 38.961038961))}+ Impressions</h3>
                    <Slider value={budget} onChange={handle_budget} min={550} max={2300} number_type="currency" />
                </div>

                <div className="image-uploads-wrapper">
                    <label>Media</label>
                    <div className="image-uploads">
                        <UploadImageMini onChange={handle_images} />

                        {images?.map((url: string) => (
                            <UploadImageMini image={url} onChange={handle_images} onRemove={handle_images_remove} />
                        ))}
                    </div>
                </div>

                {/* <br /> */}
                <Button onClick={create_order} loading={loading}>Order now!</Button>

                {error && <div className="error">{error}</div>}
            </AgentMarketingForm>

        </View>
    )

}

function roundDownToNearestThousand(number: number) {
    const roundedValue = Math.floor(number / 1000) * 1000;
    return roundedValue;
}