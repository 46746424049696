import { useEffect, useState } from "react";
import { Shutter } from "../../comps/Shutter"
import { ViewHeader } from "../../comps/ViewHeader"
import { View } from "../../layout/View"
import { faBuilding, faFileInvoice, faPeopleGroup, faPerson } from "@fortawesome/free-solid-svg-icons";
import { ShutterList } from "../../comps/ShutterList";
import { ShutterAgencyListItem } from "../../comps/ShutterAgencyListItem";
import { Agency } from "../../defs/agency-model";
import { useUserStore } from "../../stores/user-store";
import { fetchAgenciesBelongingToCorporation, fetchCustomersForCorpView, fetchOrdersForCorpView, fetchTeamsForCorpView } from "../../api/corporation.api";
import { ShutterColumnWrapper } from "../../comps/ShutterColumnWrapper";
import { Team } from "../../defs/team-model";
import { Filter } from "../../defs/corporation-model";
import { ShutterTeamListItem } from "../../comps/ShutterTeamListItem";
import { Customer } from "../../defs/customer-model";
import { ShuttersCustomerListItem } from "../../comps/ShutterCustomerListItem";
import { useModals } from "../../hooks/modal-provider";
import { EditViewAgencyModal } from "../../comps/EditViewAgencyModal";
import { AddAgencyModal } from "../../comps/AddAgencyModal";
import { EditViewTeamModal } from "../../comps/EditViewTeamModal";
import { AddTeamModal } from "../../comps/AddTeamModal";
import { AddCustomerModal } from "../../comps/AddCustomerModal";
import { EditViewCustomerModal } from "../../comps/EditViewCustomerModal";
import { ShutterOrderListItem } from "../../comps/ShutterOrderListItem";
import { Order } from "../../defs/order-model";
import { OrgViewOrderModal } from "../../comps/OrgViewOrderModal";
import { useNavigate } from "react-router-dom";

/**
 * In the future having the list items infinately loadable would be nice... -> For the time being no one will have that many items, so we are all good :)
 */
export const ViewOrgView = () => {
    const modals = useModals();
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const [corp_id, setCorpId] = useState<number>(null!);

    // Agencies
    const [loading_agencies, setLoadingAgencies] = useState(true);
    const [agencies_error, setAgenciesError] = useState("");
    const [agencies, setAgencies] = useState<Agency[]>([]);

    // Teams
    const [loading_teams, setLoadingTeams] = useState(true);
    const [teams_error, setTeamsError] = useState("");
    const [teams, setTeams] = useState<Team[]>([]);

    // Customers
    const [loading_customers, setLoadingCustomers] = useState(true);
    const [customers_error, setCustomersError] = useState("");
    const [customers, setCustomers] = useState<Customer[]>([]);

    // Orders
    const [orders, setOrders] = useState<any[]>([]);
    const [loading_orders, setLoadingOrders] = useState(true);
    const [orders_error, setOrdersError] = useState("");

    // Views
    const [agency_view_open, setAgencyViewOpen] = useState(true);
    const [teams_view_open, setTeamsViewOpen] = useState(true);
    const [customers_view_open, setCustomersViewOpen] = useState(true);
    const [orders_view_open, setOrdersViewOpen] = useState(true);
    const [filters, setFilters] = useState<Filter[]>([]);

    // Load the agencies
    const loadAgencies = async () => {
        setAgenciesError("");
        setLoadingAgencies(true);
        setAgencies([]);
        try {
            const temp = await fetchAgenciesBelongingToCorporation(user.corporation.id);
            setAgencies(temp);
        } catch (err) {
            setAgenciesError("Failed to load the agencies...");
        }
        setLoadingAgencies(false);
    }

    // Load the teams belonging to the corporation and with the agency filters
    const loadTeams = async (f?: Filter[]) => {
        setTeamsError("");
        setLoadingTeams(true);
        setTeams([]);
        try {
            const temp = await fetchTeamsForCorpView(user.corporation.id, f || filters);
            await loadCustomers(f);
            setTeams(temp);
        } catch (err) {
            setTeamsError("Failed to load the teams...");
        }
        setLoadingTeams(false);
    }

    // Load the customers belonging to the corporation and with the team + agency filters
    const loadCustomers = async (f?: Filter[]) => {
        setCustomersError("");
        setLoadingCustomers(true);
        setCustomers([]);
        try {
            const temp = await fetchCustomersForCorpView(user.corporation.id, f || filters);
            await loadOrders(f);
            setCustomers(temp);
        } catch (err) {
            setOrdersError("Failed to load Customers...");
        }
        setLoadingCustomers(false);
    }

    // Load the orders for the organisation
    const loadOrders = async (f?: Filter[]) => {
        setOrdersError("");
        setLoadingOrders(true);
        setOrders([]);
        try {
            const temp = await fetchOrdersForCorpView(user.corporation.id, f || filters);
            setOrders(temp);
        } catch (err) {
            setOrdersError("Failed to load the orders...");
        }
        setLoadingOrders(false);
    }

    // Check if the agency is selected
    const isAgencySelected = (agency: Agency) => {
        return Boolean(filters.find((filter) => filter.value === agency.id && filter.name === "agency") !== undefined);
    }

    // Check if the team is selected
    const isTeamSelected = (team: Team) => {
        return Boolean(filters.find((filter) => filter.value === team.id && filter.name === "team") !== undefined);
    }

    // Check if the customer is selected
    const isCustomerSelected = (customer: Customer) => {
        return Boolean(filters.find((filter) => filter.value === customer.id && filter.name === "customer") !== undefined);
    }


    // Add an agency filter
    const addAgencyFilter = (agency: Agency) => {
        let temp = [...filters];

        // Reset the following filters
        temp = temp.filter((filter) => filter.name !== "team");
        temp = temp.filter((filter) => filter.name !== "customer");
        temp = temp.filter((filter) => filter.name !== "order");

        // If this agency is already selected, remove it
        if (isAgencySelected(agency)) {
            const index = temp.findIndex((filter) => filter.value === agency.id && filter.name === "agency");
            temp.splice(index, 1);
            setFilters(temp);
            loadTeams(temp);
            loadOrders(temp);
            return;
        }

        temp.push({
            name: "agency",
            value: agency.id
        });

        console.log("FITLERS: ", temp);

        setFilters(temp);
        loadTeams(temp);
        loadOrders(temp);
    }

    // Add a team filter
    const addTeamFilter = (team: Team) => {
        const temp = [...filters];

        // If this team is already selected, remove it
        if (isTeamSelected(team)) {
            const index = temp.findIndex((filter) => filter.value === team.id && filter.name === "team");
            temp.splice(index, 1);
            setFilters(temp);
            loadCustomers(temp);
            loadOrders(temp);
            return;
        }

        temp.push({
            name: "team",
            value: team.id
        });
        setFilters(temp);
        loadCustomers(temp);
        loadOrders(temp);
    }

    // Add a customer filter
    const addCustomerFilter = (customer: Customer) => {
        const temp = [...filters];

        // If this customer is already selected, remove it
        if (isCustomerSelected(customer)) {
            const index = temp.findIndex((filter) => filter.value === customer.id && filter.name === "customer");
            temp.splice(index, 1);
            setFilters(temp);
            loadOrders(temp);
            return;
        }

        temp.push({
            name: "customer",
            value: customer.id
        });
        setFilters(temp);
        loadOrders(temp);
    }

    // Open edit agency modal
    const openEditAgencyModal = (agency: Agency) => {
        // Determine if user can edit
        const can_edit = user?.customer?.role === "CORP_ADMIN";

        modals.create({
            title: `${agency.name}`,
            content: <EditViewAgencyModal id={agency.id} edit={can_edit} />,
            on_close: () => loadAgencies()
        })
    }

    // Open add agency modal
    const openAddAgencyModal = () => {
        modals.create({
            id: "add-agency",
            title: `Add Agency`,
            content: <AddAgencyModal onAdd={() => {
                loadAgencies()
                modals.remove("add-agency")
            }} />,
            on_close: () => loadAgencies()
        })
    }

    // Open edit team modal
    const openEditTeamModal = (team: Team) => {
        // Determine if user can edit
        let can_edit = true;

        modals.create({
            title: `${team.name}`,
            content: <EditViewTeamModal id={team.id} edit={can_edit} />,
            on_close: () => loadTeams()
        });
    }

    // Open add new team modal
    const openAddTeamModal = () => {
        modals.create({
            id: "add-team",
            title: `Add Team`,
            content: <AddTeamModal agencies={agencies} onAdd={() => {
                loadTeams()
                modals.remove("add-team")
            }} />,
            on_close: () => loadTeams()
        })
    }

    // Open add new customer modal
    const openAddCustomerModal = () => {
        modals.create({
            id: "add-customer",
            title: `Add Customer`,
            content: <AddCustomerModal onAdd={() => {
                loadCustomers();
                modals.remove("add-customer");
            }} agencies={agencies} />,
            on_close: () => loadCustomers()
        })
    }

    // Open edit customer modal
    const openEditCustomerModal = (customer: Customer) => {
        // Determine if user can edit
        let can_edit = customer.id === user.customer.id;
        if (user?.customer?.role === "CORP_ADMIN") can_edit = true;

        modals.create({
            title: `Edit Customer`,
            content: <EditViewCustomerModal id={customer.id} edit={can_edit} />,
            on_close: () => loadCustomers()
        })
    }

    // Open the View Order modal
    const openViewOrderModal = (order: Order) => {
        modals.create({
            title: `View Order`,
            content: <OrgViewOrderModal id={order.id} onClose={() => { }} />,
            on_close: () => loadOrders()
        })
    }

    useEffect(() => {
        if (user) {
            loadAgencies();
            loadTeams();
            loadCustomers();

            if (user?.customer?.role !== "CORP_ADMIN") navigate("/dash/home");
        }
    }, []);

    useEffect(() => {
        console.log("Filters changed: ", filters);
    }, [filters]);

    return (
        <View>
            <ViewHeader back title="Organisation View" />
            <ShutterColumnWrapper>
                <Shutter icon={faBuilding} title="Agencies" open={agency_view_open} onOpen={() => setAgencyViewOpen(true)} onClose={() => setAgencyViewOpen(false)} onAdd={openAddAgencyModal}>
                    <ShutterList loading={loading_agencies} data={agencies} renderItem={(item: Agency) =>
                        <ShutterAgencyListItem open={agency_view_open} agency={item} onClick={addAgencyFilter} selected={isAgencySelected(item)} onEdit={(item) => openEditAgencyModal(item)} />
                    } />
                </Shutter>
                <Shutter icon={faPeopleGroup} title="Teams" open={teams_view_open} onOpen={() => setTeamsViewOpen(true)} onClose={() => setTeamsViewOpen(false)} onAdd={openAddTeamModal}>
                    <ShutterList loading={loading_teams} data={teams} renderItem={(item) =>
                        <ShutterTeamListItem open={teams_view_open} team={item} onClick={addTeamFilter} selected={isTeamSelected(item)} onEdit={(item) => openEditTeamModal(item)} />
                    } />
                </Shutter>
                <Shutter icon={faPerson} title="Staff" open={customers_view_open} onOpen={() => setCustomersViewOpen(true)} onClose={() => setCustomersViewOpen(false)} onAdd={openAddCustomerModal}>
                    <ShutterList loading={loading_customers} data={customers} renderItem={(item) =>
                        <ShuttersCustomerListItem open={customers_view_open} customer={item} onClick={addCustomerFilter} selected={isCustomerSelected(item)} onEdit={() => openEditCustomerModal(item)} />
                    } />
                </Shutter>
                <Shutter icon={faFileInvoice} title="Orders" open={orders_view_open} onOpen={() => setOrdersViewOpen(true)} onClose={() => setOrdersViewOpen(false)}>
                    <ShutterList loading={loading_orders} data={orders} renderItem={(item) =>
                        <ShutterOrderListItem open={orders_view_open} order={item} onClick={() => openViewOrderModal(item)} />
                        // <ShuttersCustomerListItem open={orders_view_open} customer={item} onClick={() => null} />
                    } />
                </Shutter>
            </ShutterColumnWrapper>
        </View>
    )
}