import "../styles/facebook-metrics.comp.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add_commas } from "../utils/numbers";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { InlineLoader } from "./InlineLoader";

export const MetricCard = ({ metric, icon, label, tooltip }: { metric: number, icon: IconDefinition, label: string, tooltip: string }) => {
    return (
        <div className="metric-card" data-tooltip-id="metric-card-tooltip"
            data-tooltip-content={tooltip}>
            <div className="metric-card-icon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="metric-card-info">
                <label className="metric-card-label">{label}</label>
                {!metric ? <InlineLoader color="#232323" /> : (
                    <div className="metric-card-value">{add_commas(metric)}</div>
                )}
            </div>

            <Tooltip id="metric-card-tooltip" place="bottom" style={{ maxWidth: 250, zIndex: 10000 }} />
        </div>);
}

