import "../styles/copy-bar.comp.scss"

export const CopyBar = () => {
    return (
        <div className="copy-bar">
            <div className="copy-bar-copy">
                &copy; {String(new Date().getFullYear()) || ''} 23rd Street
                Marketing
            </div>
            <a
                className="copy-link"
                href={'https://23rdst.com.au/terms#privacy-policy'}
            >
                Privacy Policy
            </a>
            <a className="copy-link" href={'https://23rdst.com.au/terms#terms'}>
                Terms of Use
            </a>
            <a
                className="copy-link"
                href={'https://23rdst.com.au/terms#cookies'}
            >
                Cookies
            </a>
        </div>
    )
}