import { client } from "./api.config";

export const login = async (email: string, password: string, setError?: (error: string) => void) => {
    try {
        const res = await client().post("/auth/login", {
            email,
            password
        });

        if (res.status !== 200) {
            throw new Error("Invalid credentials");
        }

        return res.data;
    } catch (err) {
        if (setError) setError("Invalid credentials");
        return null;
    }
}