import "../../../styles/select-package-view-create-order.scss";
import { getCustomerPackages } from "../../../api/package.api";
import { InlineLoader } from "../../../comps/InlineLoader";
import { PackageCard } from "../../../comps/PackageCard";
import { accent_color } from "../../../defs/color.def";
import { Package } from "../../../defs/order-model";
import { useUserStore } from "../../../stores/user-store";
import { useEffect, useState } from "react";

export interface Props {
    package?: Package;
    setPackage: (p: Package) => void;
}

export const SelectPackageView = (props: Props) => {
    const user = useUserStore((state: any) => state.user);
    const [loading, setLoading] = useState(true);
    const [packages, setPackages] = useState<Package[]>([]);

    const load = async () => {
        setLoading(true);
        try {
            let packages = await getCustomerPackages(user.customer.id || 0,);

            packages = packages?.sort((a: Package, b: Package) => a.price - b.price);

            console.log("Got the packages: ", packages)

            setPackages(packages);
        } catch (err) {
            console.error("Failed to load the packages: ", err);
        }
        setLoading(false);
    }
    useEffect(() => {
        load();
    }, []);

    if (loading) return (<div className="page">
        <InlineLoader color={accent_color} />
    </div>);

    return (
        <div className="page package-page">
            <h1>Select a package</h1>
            <div className="package-list">
                {packages?.map((pack: Package, i: number) => <PackageCard key={i} package={pack} onClick={props.setPackage} sel_package={props.package} />)}
            </div>
        </div>
    )
}