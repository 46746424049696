import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "../styles/number-stat-box.comp.scss";
import { InlineLoader } from "./InlineLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export interface Props {
    title: string;
    icon?: IconDefinition;
    count: number;
    desc: string;
    loading?: boolean;
}

export const NumberStatBox = (props: Props) => {
    const [suffix, setSuffix] = useState("");
    const [current, setCurrent] = useState<String>("");

    // Simplify the number to a more readable format max len 5 chars
    const simplify = (): void => {
        if (props.count === 0) {
            setCurrent("0");
            setSuffix("");
            return;
        }

        if (props.count < 1000) {
            setSuffix("");
            setCurrent(props.count.toString());
            return;
            // return props.count.toString();
        }
        if (props.count < 1000000) {
            setCurrent((props.count / 1000).toFixed(1))
            setSuffix("K");
            return;

            // return (props.count / 1000).toFixed(1) + "K";
        }
        if (props.count < 1000000000) {
            setCurrent((props.count / 1000000).toFixed(1))
            // setCurrent(props.count / 1000000)
            setSuffix("M");
            return;

            // return (props.count / 1000000).toFixed(1) + "M";
        }

        setCurrent((props.count / 1000000000).toFixed(1))
        // setCurrent(props.count / 1000000000)
        setSuffix("B");
        return;

        // return (props.count / 1000000000).toFixed(1) + "B";
    }

    useEffect(() => {
        // Once we get the count need to simplify the number and set the suffix
        simplify();
    }, [props.count]);

    return (
        <div className="number-count">
            {props.loading ? (<div className="loading"><InlineLoader /></div>) : (
                <div className="count">{current}{suffix}</div>)}
            <div className="title">
                {props.icon && <FontAwesomeIcon icon={props.icon} />}
                {props.title}</div>
            <div className="desc">{props.desc}</div>
        </div >
    )
}