import "../../styles/view-vpa-order.view.scss";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock, faFileInvoice, faRunning } from "@fortawesome/free-solid-svg-icons";
import { getOrder, get_agent_marketing_option, get_agent_marketing_type_icon, removeSingleAmImage, uploadSingleAmImage } from "../../api/order.api";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Row } from "../../layout/RowLayout";
import { Container } from "../../layout/ContainerLayout";
import { StatusBar } from "../../comps/StatusBar";
import { UploadImageMini } from "../../comps/UploadMiniImage";
import { Button } from "../../comps/Button";
import { AmType } from "../../defs/order-model";
import { SecondaryButton } from "../../comps/SecondaryButton";
import { SlideshowHeader } from "../../comps/SlideShowHeader";
import { Warning } from "../../comps/Warning";
import { fetchTeam } from "../../api/team.api";
import { TwoColumn } from "../../layout/TwoColumn";
import { Text } from "../../comps/Text";


export const ViewAmOrderView = () => {
    const navigate = useNavigate();
    // Get the id
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("");
    const [campaign, setCampaign] = useState<any>(null!)

    const [team, setTeam] = useState<any>(null!);
    const [team_loading, setTeamLoading] = useState(false);

    // Load a team
    const load_team = async () => {

        if (!campaign) return;
        setTeamLoading(true);

        try {
            const team = await fetchTeam(campaign.team.id);
            setTeam(team);
        } catch (err) {
            setError('Failed to load the team information.')
        }

        setTeamLoading(false);
    }


    const load = async () => {
        if (!id) return;

        setLoading(true);
        setError("");

        try {
            const res = await getOrder(+id);
            console.log("Regot the order: ", res);
            setCampaign(res as any);
        } catch (err) {
            console.error("Failed to load the Campaign.");
            setError("Failed to load the campaign.");
        }

        setLoading(false);
    }

    const open_campaign_report = async () => {
        if (!campaign) return;

        if (campaign.report_id) {
            navigate(`/agent-marketing-report/${campaign.report_id}`);
        }
    }

    // Handle a new image
    const handle_images = async (image: string) => {
        if (!campaign || !id) return;

        // Try to upload the image and then reload the 
        try {
            const res = await uploadSingleAmImage(+id, image);
            load();
        } catch (err) {
            console.error("Failed to update the order.", err)
        }
    }

    // Handle removing an image
    const handle_images_remove = async (image: string) => {
        if (!campaign || !id) return;

        try {
            const media_id = campaign?.am_order?.amOrderMedia?.find((m: any) => m.url === image)?.id;
            if (!media_id) return;
            const res = await removeSingleAmImage(+id, media_id);
            load();
        } catch (err) {
            console.error("Failed to delete the image.");
        }
    }

    useEffect(() => {
        if (id) load();
    }, [id]);

    useEffect(() => {
        if (campaign) load_team();
    }, [campaign])

    return (
        <View>
            <ViewHeader title="Agent Marketing Order" back />

            <Body>
                {/* Show the media in the form of a slide show */}
                {campaign?.am_order?.amOrderMedia?.length > 0 && (
                    <SlideshowHeader images={campaign?.am_order?.amOrderMedia?.map((item: any) => item.url) || []} />
                )}
                {/* <Row >
                    {campaign?.am_order?.media?.length > 0 &&
                        (
                            <div className="order-header-image" style={{
                                backgroundImage: `url("${campaign?.media?.[0]?.image}")`
                            }}
                            />
                        )}
                </Row> */}

                <Row>
                    <Container id="no-margin">
                        <h1><FontAwesomeIcon icon={get_agent_marketing_type_icon(campaign?.am_order?.type)} /> {" "}{get_agent_marketing_option(campaign?.am_order?.type)?.label}</h1>
                        <h2 className="header-details">Details</h2>

                        {(campaign?.am_order?.address && campaign?.am_order?.type === AmType?.SOLD) && (<div className="general-field">
                            <label>Address</label>
                            <h2>{campaign?.am_order?.address}</h2>
                        </div>)}

                        <div className="general-field">
                            <label>Budget</label>
                            <h2>${campaign?.am_order?.budget / 100}</h2>
                        </div>

                        <div className="date-fields">
                            <label>Campaign Date</label>
                            {campaign?.am_order?.date && (
                                <h2> {format(new Date(campaign?.am_order?.date), "do LLLL yyyy")}</h2>
                            )}
                        </div>

                        <div className="note-fields">
                            <label>Notes</label>
                            <h2>{campaign?.am_order?.notes}</h2>
                        </div>
                    </Container>
                </Row>
                <TwoColumn>
                    <div />
                    <div />
                    <div />
                    <div />
                    <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                    <Text label="Team" value={campaign?.team?.name || "N/A"} />

                    {/* List out the org and the agency */}
                    <Text label="Company" value={team?.agency?.corporation?.name || "N/A"} />
                    <Text label="Agency" value={team?.agency?.name || "N/A"} />

                </TwoColumn>


                <br />
                <br />
                <Row>
                    <Container id="no-margin">
                        <label>Order Status</label>
                        <StatusBar statuses={statuses as any} status={campaign?.status} start_date={new Date()} end_date={new Date()} />
                    </Container>
                </Row>

                <div className="image-fields">
                    <label>Associated Media</label>
                    <div className="image-uploads">
                        <UploadImageMini onChange={handle_images} />

                        {campaign?.am_order?.amOrderMedia?.map((media: any, i: number) => (
                            <UploadImageMini key={i} image={media.url} onChange={handle_images} onRemove={handle_images_remove} />
                        ))}
                    </div>
                </div>

                {campaign?.facebook_ad_id && campaign?.report_id && campaign?.facebook_ad_id !== "[Object] [Object]" && campaign?.facebook_ad_id !== "undefined" && (
                    <div className="view-report-section">
                        <h2>View your live report here!</h2>
                        <p>This report is live and always up to date. Once clicked you can copy the link and share with anyone and also download a PDF version.</p>
                        <SecondaryButton onClick={open_campaign_report}><FontAwesomeIcon icon={faFileInvoice} />{" "}View Report</SecondaryButton>
                    </div>
                )}

                {campaign && !campaign?.facebook_ad_id && campaign?.facebook_ad_id !== "[Object] [Object]" && campaign?.facebook_ad_id !== "undefined" && (
                    <Warning type={"info"} title={"Where's my report?"}>
                        <p>Once your campaign has started running a live report will be sent to your email.</p>
                        <br />
                    </Warning>
                )}



                <div className="bottom-section" />
            </Body >

        </View >
    )
}

// Hard coded statuses -> Need to grab these in the future -> To make this more dynamic?
const statuses = [{
    name: "Pending",
    color: "red",
    icon: faClock,
}, {
    name: "Active",
    color: "green",
    icon: faRunning
}, {
    name: "Completed",
    color: "blue",
    icon: faCheckCircle
}]