import { Filter } from "../defs/corporation-model";
import { Team } from "../defs/team-model";
import { client } from "./api.config"

// Get a team via it's ID
export const fetchTeam = async (id: number) => {
    const res = await client().get(`/team/${id}`);
    return res.data;
}

// Update a team
export const updateTeam = async (id: number, data: any) => {
    data = {
        name: data.name,
        lead_customer_id: data.lead_customer_id
    };

    const res = await client().put(`/team/${id}`, data);
    return res.data;
}

// Create a new team
export const addTeam = async (data: { name: string, agency_id: number, member: number }) => {
    const res = await client().post(`/team`, data);
    return res.data;
}

// Add a customer to a team
export const addTeamMemberToTeam = async (team_id: number, customer_id: number) => {
    const res = await client().post(`/team/${team_id}/member/${customer_id}`);
    return res.data;
}

// Set the lead customer for a team
export const setTeamLeadCustomer = async (team_id: number, customer_id: number) => {
    const res = await client().post(`/team/${team_id}/leader/${customer_id}`);
    return res.data;
}

// Remove a customer from a team
export const removeTeamMemberFromTeam = async (team_id: number, customer_id: number) => {
    const res = await client().delete(`/team/${team_id}/member/${customer_id}`);
    return res.data;
}