import '../../../styles/confirm-order.create-order.scss';
import { Button } from '../../../comps/Button';
import { Text } from "../../../comps/Text";
import { NewVpaOrder } from '../../../defs/order-model';
import { differenceInDays, format, subDays } from 'date-fns';

interface Props {
    order: NewVpaOrder;
    create: any;
    back: any;
    loading: boolean;
    error: string;
}


export const ConfirmOrderView = (props: Props) => {
    return (
        <div className="page order-page">
            <div className="order-page-inner">
                <div className="left">
                    <h1>Confirm Order</h1>
                    <p>
                        Please confirm your order details. If you need to make changes feel free to go back.
                    </p>
                    <div className="confirm-button  confirm-button-desktop">
                        <Button
                            style={{
                                maxWidth: '200px',
                            }}
                            onClick={() => props.create()}
                            loading={props.loading}
                        >Order Now</Button>
                    </div>
                    {props.error && (
                        <div className="error">{props.error}</div>)}
                </div>
                <div className="confirm-order-details">
                    <h2>Order Overview</h2>
                    {props.order.team && (
                        <Text label="Team" value={props.order?.team?.name || ""} />
                    )}
                    <Text label="Address" value={props.order.address} />
                    {props.order.package && (
                        <Text
                            label="Package"
                            value={`${props.order?.package?.name} - $${props.order?.package?.price}`}
                        />
                    )}
                    <Text
                        label="Campaign Timeline"
                        value={`${props.order.start_date
                            ? format(props.order.start_date, 'dd/MM/yyyy')
                            : 'N/A'
                            } - ${props.order.end_date
                                ? format(props.order.end_date, 'dd/MM/yyyy')
                                : 'N/A'
                            }`}
                    />
                    {props.order.start_date && props.order.end_date && (
                        <Text
                            label="Campaign Length"
                            value={`${Math.abs(
                                differenceInDays(
                                    props.order.start_date,
                                    props.order.end_date
                                )
                            )} days`}
                        />
                    )}

                    <Text
                        label="Additional Notes"
                        value={props.order.notes || 'N/A'}
                    />
                </div>

                <div className="confirm-button confirm-button-mobile">
                    <Button
                        style={{
                            maxWidth: '200px',
                        }}
                        onClick={() => props.create()}
                        loading={props.loading}
                    >Order Now</Button>
                </div>
            </div>
        </div >
    );
};
