import { Button } from '../../../comps/Button';
import { Textarea } from '../../../comps/TextArea';
import '../../../styles/select-notes.create-order.scss';

interface Props {
    next: any;
    back: any;
    notes: string;
    setNotes: any;
}

/**
 * Just some order details such as the notes, keywords, Google Drive or Dropbox links
 */
export const SelectNotes = (props: Props) => {
    return (
        <div className="page extra-page">
            <div className="extra-page-inner">
                <div className="left">
                    <h1>Anything else we need to know?</h1>
                    <p>Enter anything you think will be beneficial for the campaign. <br />From keywords, to image photo links such as Google Drive or Dropbox.</p>

                    <div className="confirm-button confirm-button-desktop">
                        <Button
                            style={{
                                maxWidth: '200px',
                            }} onClick={() => props.next()}>Continue</Button>
                    </div>
                </div>

                <div className="order-deatils-form">
                    <Textarea
                        rows={6}
                        label="Additional Notes"
                        value={props.notes}
                        onChange={props.setNotes}
                        placeholder="Captivating rose garden adorning the front, inviting large pool for relaxation, conveniently located just 20 minutes away from the bustling city center."
                    />
                </div>

                <div className="confirm-button confirm-button-mobile">
                    <Button
                        style={{
                            maxWidth: '200px',
                        }}
                        onClick={() => props.next()}>
                        Continue</Button>
                </div>

            </div>
        </div>
    );
};
