import "../styles/secondary-button.comp.scss";
import { ReactNode } from "react";
import { InlineLoader } from "./InlineLoader";
import { accent_color } from "../defs/color.def";

interface Props {
    disabled?: boolean;
    onClick?: () => void;
    children: ReactNode;
    loading?: boolean;
}

export const SecondaryButton = (props: Props) => {
    return (
        <button className="secondary-button" disabled={props.disabled} onClick={props.onClick}>
            {props.loading ? <InlineLoader color={accent_color} /> : props.children}
        </button>
    )
}