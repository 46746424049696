import { Customer } from "../defs/customer-model";
import { client } from "./api.config"

// Fetch a customer using their id
export const fetchCustomer = async (id: string | number): Promise<Customer> => {
    const res = await client().get(`/customer/${id}`)
    return res.data;
}
// Update a customer
export const updateCustomer = async (customer: Customer): Promise<Customer> => {
    const res = await client().put(`/customer/${customer.id}`, customer)
    return res.data;
}

// Create a customer
export const createCustomer = async (customer: Partial<Customer>): Promise<Customer> => {
    const res = await client().post(`/customer`, customer)
    return res.data;
}

// Update customer display picture
export const updateCustomerDisplayPicture = async (id: number, form: FormData): Promise<{ dp: string }> => {
    const res = await client().post(`/customer/${id}/dp`, form);
    return res.data;
}

// Get the customer via
export const fetchCustomerForOnboarding = async (token: string): Promise<Customer> => {
    const res = await client().get(`/customer/${0}/onboarding/${token}`)
    return res.data;
}

// Set the password for an onboarding customer
export const setOnboardingPassword = async (id: string | number, token: string, password: string): Promise<Customer> => {
    const res = await client().post(`/customer/${id}/onboard`, { password, token })
    return res.data;
}

// Send a forgot password email
export const sendForgotPassword = async (email: string) => {
    const res = await client().post(`/customer/forgot`, { email })
    return res.data;
}

// Actually reset the customers password
export const resetPassword = async (token: string, password: string) => {
    const res = await client().post(`/customer/reset`, { token, password })
    return res.data;
}

// Get the total orders for today
export const fetchTotalOrdersToday = async (id: string | number): Promise<{ total: number }> => {
    const res = await client().get(`/customer-count/${id}/orders/today`)
    return res.data;
}

// Get the total orders ever
export const fetchTotalOrdersEver = async (id: string | number): Promise<{ total: number }> => {
    const res = await client().get(`/customer-count/${id}/orders/ever`)
    return res.data;
}

// Get the total customers ever
export const fetchTotalCustomersEver = async (id: string | number): Promise<{ total: number }> => {
    const res = await client().get(`/customer-count/${id}/customers/ever`)
    return res.data;
}

// Get the total impressions ever, for graph
export const fetchTotalImpressionsEver = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/meta/impressions`);
    return res.data;
}

// GEt the latest orders for a customer
export const fetchLatestCustomerOrders = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/latest-orders`);
    return res.data;
}

// Get the latest active orders
export const fetchLatestActiveCustomerOrders = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/latest-active-orders`);
    return res.data;
}