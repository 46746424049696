import "../styles/warning.comp.scss";
import { faBell, faInfo, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

interface Props {
    type: "warning" | "alert" | "info";
    title: string;
    children: ReactNode;
}

export const Warning = (props: Props) => {
    const gen_icon = () => {
        switch (props.type) {
            case "alert":
                return faBell;
            case "info":
                return faInfo;
            case "warning":
                return faWarning;
            default:
                return faWarning;
        }
    }

    return (
        <div className="warning">
            <div className="warning-header">
                <div className="warning-icon">
                    <FontAwesomeIcon icon={gen_icon()} />
                </div>
                <div className="warning-title">
                    {props.title}
                </div>
            </div>

            <div className="warning-msg">
                {props.children}
            </div>
        </div>
    )

}