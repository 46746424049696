
import axios from "axios";

// Pick the right URL
const prod_url = "https://api.23rdst.com.au";
const dev_url = "http://192.168.1.7:8080";
export const fe_url = "https://edge.23rdst.com.au";

// Export the URL
export const api_url = process.env.NODE_ENV === "production" ? prod_url : dev_url;

// Export the API
export const client = () => {
    // Try to get the token from local storage
    const user = localStorage.getItem('user-storage');
    const token = user ? JSON.parse(user)?.state?.user?.token : null;

    return axios.create({
        baseURL: api_url,
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    });
};
