import "../styles/slider.comp.scss";
import ReactSlider from "react-slider";
import { add_commas } from "../utils/numbers";

interface Props {
    min?: number;
    max?: number;
    label?: string;
    onChange: (value: number) => void;
    value?: number;
    number_type?: "currency" | "percent" | undefined;
}

export const Slider = (props: Props) => (
    <div className="slider-wrapper">
        <ReactSlider
            className="slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            value={props.value}
            onChange={props.onChange}
            min={(props.min || 0)}
            max={(props.max || 100)}
            step={110}
        />
        <div className="slider-bottom">
            <div className="slider-min">{`${props.number_type === "currency" ? "$" : ""}${add_commas(props.min || 0)}`}</div>
            <div className="slider-min">{`${props.number_type === "currency" ? "$" : ""}${add_commas(props.max || 100)}`}</div>
        </div>
    </div>
)