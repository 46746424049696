import "../styles/shutter.comp.scss"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useUserStore } from "../stores/user-store";

export interface Props {
    icon?: IconDefinition;
    title: string;
    open: boolean;
    children: React.ReactNode;
    onOpen?: () => void;
    onClose?: () => void;
    onAdd?: () => void;
    can_edit?: boolean
}

export const Shutter = (props: Props) => {
    const user = useUserStore((state: any) => state.user);

    const handleMenubarClick = () => {
        if (props.open) {
            props.onClose && props.onClose();
        } else {
            props.onOpen && props.onOpen();
        }
    }

    const handleOpen = (e: any) => {
        e.stopPropagation();
        props.onAdd && props.onAdd();
    }

    return (
        <motion.div className="shutter"
            id={props.open ? "shutter-open" : "shutter-closed"}
            animate={{
                width: props.open ? "100%" : "35px",
            }}
            transition={{
                duration: 0.3
            }}
        >
            <div className="shutter-menu" onClick={() => handleMenubarClick()} >
                <div className="shutter-menu-left">
                    {props?.icon && <div className="icon">
                        <FontAwesomeIcon icon={props.icon} />
                    </div>}
                    {props.open && <div className="title">{props.title}</div>}
                </div>
                {props.open && <div className="shutter-menu-right" onClick={handleOpen}>
                    <div className="icon" ><FontAwesomeIcon icon={faPlusCircle} /></div>
                </div>}
            </div>

            <div className="shutter-list">
                {props.children}
            </div>
        </motion.div>
    );
}