import '../styles/text.comp.scss';
import { StyleHTMLAttributes } from 'react';

interface Props {
    label: string;
    value: string;
    style?: StyleHTMLAttributes<HTMLDivElement>;
}

export const Text = (props: Props) => {
    return (
        <div className="text" >
            <label>{props.label}</label>
            <div className="value" style={props.style}>{props.value}</div>
        </div>
    );
};
