import { format } from "date-fns";
import { client } from "./api.config";

export interface MetaTotals {
    impressions: number;
    clicks: number;
    engagement: number;
    reach: number;
}


// This function is for getting the stats for an Agent Marketing Campaign
export const get_meta_stats = async (id: string) => {
    const res = await client().get(`/meta/${id}/meta-stats`);

    return res.data.map((data: any) =>
    ({
        ...data,
        impressions: parseInt(data.impressions),
    }));
}


// This function is for getting the totals for an AGent Marketing Campaign
export const get_meta_totals = async (id: string): Promise<any> => {
    const res = await client().get(`/meta/${id}/meta-totals`);

    // Look in the actions
    const actions = res.data?.actions;

    // Try to find the action_type === post_engagement
    const engagement = actions.find((action: any) => action.action_type === 'post_engagement');

    return {
        ...res.data,
        engagement: engagement ? engagement.value : "N/A"
    };
}


export const get_meta_demos = async (report_id: string): Promise<any> => {
    const res = await client().get(`/meta/${report_id}/meta-demo`);

    let tmp: any[] = [];

    for (const demo of res.data as any[]) {
        // See if the age group exists
        const age_group = tmp.findIndex((item: any) => item.age === demo.age);

        // If the age_group doesn't exists insert a new one
        if (age_group === -1) {
            tmp.push({
                age: String(demo.age),
                [demo.gender]: Number(demo.reach)
            });
        } else {
            tmp[age_group] = {
                ...tmp[age_group],
                [demo.gender]: Number(demo.reach)
            }
        }
    }

    // Sort the tmp via the age, the age is a string 15-25, so grab the first two chars and convert to a number
    tmp = tmp.sort((a: any, b: any) => parseInt(a.age.substring(0, 2)) - parseInt(b.age.substring(0, 2)));
    tmp = tmp.filter((item: any) => item.age !== '13-17');
    return tmp;
}

// Get the meta totals for a customer
export const get_meta_totals_for_customer = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/meta-totals`);

    if (res.data?.actions) {
        res.data.engagement = res.data.actions.find((action: any) => action.action_type === 'post_reaction')?.value;
    }

    return res.data;
}

// Get the meta performance stats for a customer
export const get_meta_stats_for_customer = async (customer_id: number) => {
    const res = await client().get(`/customer/${customer_id}/meta-stats`);
    const { data } = res;

    return data.map((item: any) => ({
        reach: parseInt(item.reach),
        date: item?.date_start ? format(new Date(item.date_start), "MM/dd/yyyy") : format(new Date(item.date), "MM/dd/yyyy")
    }));
}