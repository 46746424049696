import "../../styles/home-view.scss";
import { AuthResult, useUserStore } from "../../stores/user-store";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Grid } from "../../layout/GridLayout";
import { View } from "../../layout/View";
import { NumberStatBox } from "../../comps/NumberStatBox";
import { useEffect, useState } from "react";
import { faBox, faBuilding, faCalendar, faEye, faFileInvoice, faHeart, faHouse, faLink, faMousePointer, faPeopleGroup, faPerson, faRecordVinyl, faTimeline } from "@fortawesome/free-solid-svg-icons";
import { LineChart } from "../../comps/LineGraph";
import { fetchLatestActiveCustomerOrders, fetchLatestCustomerOrders, fetchTotalCustomersEver, fetchTotalImpressionsEver, fetchTotalOrdersEver, fetchTotalOrdersToday } from "../../api/customer.api";
import { MetaTotals, get_meta_stats, get_meta_stats_for_customer, get_meta_totals_for_customer } from "../../api/meta.api";
import { Link, useNavigate } from "react-router-dom";
import { Column, Table, TableDate, TableDateTooltipRender, TableText } from "../../comps/Table";
import { generateOrderStatusBgColorFromText, generateOrderStatusColor, generateOrderStatusColorFromText, renderOrdersForTable } from "../../api/order.api";
import { format } from "date-fns";
import { OrderType } from "../../defs/order-model";


// This just determines what view to render based on the logged in customers access level
export const HomeView = () => {
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user) as AuthResult;
    const [orders_today, setOrdersToday] = useState(0);
    const [total_orders, setTotalOrders] = useState(0);
    const [total_active_orders, setTotalActiveOrders] = useState(0);
    const [total_customers, setTotalCustomers] = useState(100);
    const [meta_totals, setMetaTotals] = useState<MetaTotals>(null!);
    const [meta_stats, setMetaStats] = useState<MetaTotals[]>([]);
    const [loading, setLoading] = useState(true);
    const [latest_orders, setLatestOrders] = useState<any[]>([]);
    const [top_performing, setTopPerforming] = useState<any[]>([]);

    const [top_performing_loading, setTopPerformingLoading] = useState(false);
    const [latest_orders_loading, setLatestOrdersLoading] = useState(true);
    const [meta_stats_loading, setMetaStatsLoading] = useState(true);
    const [meta_totals_loading, setMetaTotalsLoading] = useState(true);

    // Get the latest orders
    const get_latest_orders = async () => {
        setLatestOrdersLoading(true);
        try {
            const res = await fetchLatestCustomerOrders(user?.customer?.id);
            setLatestOrders(renderOrdersForTable(res));
        } catch (err) {
            console.log("Failed to get the latest orders: ", err);
        }
        setLatestOrdersLoading(false);
    }

    // Get the meta stats
    const get_meta_stats = async () => {
        setMetaStatsLoading(true);
        try {
            const res = await get_meta_stats_for_customer(user?.customer?.id);
            setMetaStats(res);
        } catch (err) {
            console.log("Failed to get the meta stats: ", err);
        }
        setMetaStatsLoading(false);
    }

    // Get the meta totals for the customer that is logged in
    const get_meta_totals = async () => {
        setMetaTotalsLoading(true);
        try {
            const res = await get_meta_totals_for_customer(user?.customer?.id);
            setMetaTotals(res);
        } catch (err) {
            console.log("Failed to get the meta totals: ", err);
        }
        setMetaTotalsLoading(false);
    }

    // Get total orders
    const get_total_orders = async () => {
        try {
            const res = await fetchTotalOrdersEver(user?.customer?.id);
            setTotalOrders(res?.total || 0);
        } catch (err) {
            console.log(err);
        }
    }

    // Get total orders today
    const get_orders_today = async () => {
        try {
            const res = await fetchTotalOrdersToday(user?.customer?.id);
            setOrdersToday(res?.total || 0);
        } catch (err) {
            console.log(err);
        }
    }

    // Get total customers
    const get_total_customers = async () => {
        try {
            const res = await fetchTotalCustomersEver(user?.customer?.id);
            setTotalCustomers(res?.total || 0);
        } catch (err) {
            console.log(err);
        }
    }

    // Get the total active orders
    const get_total_active_orders = async () => {
        try {
            const res = await fetchLatestActiveCustomerOrders(user?.customer?.id);
            setTotalActiveOrders(res?.total || 0);
        } catch (err) {
            console.log(err);
        }
    }

    // Load all
    const load_all = async () => {
        setLoading(true);
        try {
            await get_meta_totals();
            await get_total_orders();
            await get_orders_today();
            await get_total_customers();
            await get_total_active_orders();
        } catch (err) {
            console.log("Failed to load all.");
        }
        setLoading(false);
    }

    const open_row = (row: any) => {
        if (!row.id) return;

        console.log("Got the order type: ", row.order_type);

        if (row.order_type === OrderType.AM) {
            navigate(`/dash/agent-marketing/${row.id}`);
            return;
        } else {
            navigate(`/dash/orders/${row.id}`);
            return;
        }
    }

    useEffect(() => {
        load_all();
        get_meta_stats();
        get_latest_orders();
    }, []);


    return (
        <View>
            <ViewHeader title="Welcome Home" />

            <Body>
                <LineChart data={meta_stats} title="Total Reach to date" total={meta_totals?.reach} icon={faEye} loading={meta_stats_loading} />

                <br /><br />

                <Grid>
                    <NumberStatBox icon={faEye} title="Total Impressions" count={meta_totals?.impressions || 0} desc={""} loading={meta_totals_loading} />
                    <NumberStatBox icon={faPerson} title="Total Reach" count={meta_totals?.reach || 0} desc={""} loading={meta_totals_loading} />
                    <NumberStatBox icon={faMousePointer} title="Total Clicks" count={meta_totals?.clicks || 0} desc={""} loading={meta_totals_loading} />
                    <NumberStatBox icon={faHeart} title="Total Engagement" count={meta_totals?.engagement || 0} desc={""} loading={meta_totals_loading} />
                    <NumberStatBox icon={faFileInvoice} title="Total Orders" count={total_orders} desc={""} loading={loading} />
                    <NumberStatBox icon={faRecordVinyl} title="Total Live Orders" count={total_active_orders} desc={""} loading={loading} />
                </Grid>

                <br /><br />

                <div className="sub-heading">
                    <h2 id="home-heading">Latest Campaigns</h2>
                    <Link to="/dash/orders">
                        View All
                    </Link>
                </div>
                {latest_orders && latest_orders.length > 0 ? (<Table data={latest_orders} columns={LatestCampaignColumns} loading={latest_orders_loading} onClick={open_row} />) : (<p>No current orders...</p>)}
            </Body>
        </View>
    )
}

const LatestCampaignColumns = [
    {
        title: "Address",
        key: "address",
        render: TableText,
        width: "2"
    },
    {
        title: "Agency",
        key: "agency",
        render: TableText
    },
    {
        title: "Lead Agent",
        key: "lead_agent",
        render: TableText
    },
    {
        title: "Start Date",
        key: "start_date",
        render: TableDate,
        tooltip_render: TableDateTooltipRender
    },
    {
        title: "End Date",
        key: "end_date",
        render: TableDate,
        tooltip_render: TableDateTooltipRender
    },
    {
        title: "Package",
        key: "package",
        render: TableText
    },
    {
        title: "Status",
        key: "status",
        render: (data: string) => <div className="v-t-text" style={{
            color: generateOrderStatusColorFromText(data),
            backgroundColor: generateOrderStatusBgColorFromText(data),
            fontWeight: "bold",
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            width: "fit-content ",
            borderRadius: 5
        }}> {data}</div >

    }
]