import "../styles/side-panel-button.comp.scss";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

interface Props {
    icon?: IconDefinition | string;
    label: string;
    active: boolean;
    navigate: string;
}

export const SidePanelButton = (props: Props) => {
    const navigate = useNavigate();

    return (
        <div
            className="side-panel-button"
            id={props.active ? 'active' : ''}
            onClick={() => navigate(props.navigate)}
        >
            {props.icon && (
                <div className="icon">
                    {typeof props.icon === 'string' ? (
                        <img src={props.icon} alt={props.label === 'Home' ? 'Home' : "Side Panel Button"} />
                    ) : (
                        <FontAwesomeIcon icon={props.icon} />
                    )}
                </div>
            )}
            <div className="label">{props.label}</div>
        </div>
    );
};
