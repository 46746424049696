import "../styles/modal-corp-layout.scss";
import { useEffect, useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { InlineLoader } from "./InlineLoader";
import { UploadProfilePicture } from "./UploadProfilePicture";
import { Text } from "./Text";
import { Button } from "./Button";
import { Team, TeamMember } from "../defs/team-model";
import { fetchTeam, removeTeamMemberFromTeam, setTeamLeadCustomer, updateTeam } from "../api/team.api";
import { Option, Select } from "./Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useModals } from "../hooks/modal-provider";
import { AddTeamMemberModal } from "./AddTeamMemberModal";

interface Props {
    id: number;
    edit?: boolean;
}

export const EditViewTeamModal = (props: Props) => {
    const modal = useModals();
    const [data, setData] = useState<Team>(null!);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");

    // Update the data locally
    const updateLocal = (key: string, value: any) => {
        setData((prev) => {
            return {
                ...prev,
                [key]: value
            }
        });
    }

    // Update the data
    const updateData = async () => {
        setUpdating(true);
        setError('');

        try {
            await updateTeam(props.id, data);
        } catch (err) {
            setError("Failed to update the Agency...");
        }

        setUpdating(false);
    }

    // Set the lead
    const setLead = async (member: TeamMember) => {
        if (!member.customer.id) return;
        setUpdating(true);
        setError("");

        try {
            await setTeamLeadCustomer(props.id, member.customer.id);
            updateLocal("lead_customer", member.customer);
        } catch (err) {
            setError("Failed to set the lead...");
        }

        setUpdating(false);
    }

    // Remove a member
    const removeMember = async (member: TeamMember) => {
        if (!member.customer.id) return;

        setUpdating(true);
        setError("");

        try {
            await removeTeamMemberFromTeam(props.id, member.customer.id);
            updateLocal("members", data.members.filter((m) => m.customer.id !== member.customer.id));
        } catch (err) {
            setError("Failed to remove the member...");
        }

        setUpdating(false);
    }

    // Load the data
    const loadData = async () => {
        setError("");
        setLoading(true);

        try {
            const temp = await fetchTeam(props.id);
            setData(temp);
        } catch (err) {
            setError("Failed to load the Agency...");
        }
    }

    // Open add team member modal
    const openAddTeamMemberModal = () => {
        modal.create({
            id: "add-team-member",
            title: "Add Team Member",
            content: <AddTeamMemberModal team={data} onAdd={() => loadData()} />,
        })
    }

    useEffect(() => {
        loadData();
    }, [props.id]);

    return (
        <ModalLayout>
            {loading && (<InlineLoader color="" />)}

            {props.edit ? (
                <FormLayout>
                    <UploadProfilePicture onClick={(e: any) => { }} image={data?.lead_customer?.dp || ""} disabled />
                    <Input label="Name" value={data?.name} onChange={(v: string) => updateLocal("name", v)} />

                    <Text label="Lead Agent" value={data?.lead_customer ? data?.lead_customer?.first_name + " " + data?.lead_customer?.last_name : "N/A"} />

                    <div className="members-header">
                        <label>Team Members</label>
                        <div className="add-member" onClick={openAddTeamMemberModal}>
                            Add Member
                        </div>
                    </div>
                    {data?.members?.map((member: TeamMember, i) => (
                        <TeamMemberListItem key={i} member={member} setLead={() => setLead(member)} remove={() => removeMember(member)} />
                    ))}

                    <br />
                    <Button onClick={updateData} loading={updating}>Confirm Changes</Button>
                </FormLayout>
            ) : (
                <FormLayout>
                    <UploadProfilePicture onClick={(e: any) => { }} image={data?.lead_customer?.dp || ""} disabled />
                    <Text label="Name" value={data?.name} />
                    <Text label="Lead Agent" value={data?.lead_customer?.first_name + " " + data?.lead_customer?.last_name || ""} />

                    {/* List other customers involveed */}
                    <label>Team Members</label>
                    {data?.members?.map((member: TeamMember, i) => (
                        <TeamMemberListItem key={i} member={member} />
                    ))}
                </FormLayout>
            )}

        </ModalLayout>
    );
}

export interface TeamMemberListItemProps {
    member: TeamMember;
    remove?: () => void;
    setLead?: () => void;
}

export const TeamMemberListItem = (props: TeamMemberListItemProps) => {
    return (
        <div className="team-member-list-item">
            <div className="left">
                <div className="dp" style={{
                    backgroundImage: `url(${props.member.customer.dp || ''})`
                }} />
                <div className="name">
                    {props.member.customer.first_name} {props.member.customer.last_name}
                </div>
            </div>
            {
                props.remove && props.setLead && (
                    <div className="right-opts">
                        <div className="set-lead" onClick={props.setLead}>
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <div className="remove" onClick={props.remove}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                )
            }
        </div >
    )
}