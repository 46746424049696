import "../../styles/onboarding.view.scss"
import { useEffect, useState } from "react"
import { Button } from "../../comps/Button"
import { Input } from "../../comps/Input"
import { Logo } from "../../comps/Logo"
import { ViewHeader } from "../../comps/ViewHeader"
import { FormLayout } from "../../layout/FormLayout"
import { View } from "../../layout/View"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { fetchCustomerForOnboarding, setOnboardingPassword } from "../../api/customer.api"
import { InlineLoader } from "../../comps/InlineLoader"
import { accent_color } from "../../defs/color.def"
import { Customer } from "../../defs/customer-model"
import { useUserStore } from "../../stores/user-store"


// Onboarding view
export const OnboardingView = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const [customer, setCustomer] = useState<Customer>(null!);
    const setUser = useUserStore((state: any) => state.setUser);

    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");

    const check_passwords = () => {
        if (password !== confirm_password) {
            setError("Passwords do not match.");
            return false;
        }



        return true;
    }

    // Load the user data
    const load_user = async () => {
        setLoading(true);
        setError("");
        try {
            const user = await fetchCustomerForOnboarding(id || "");
            setCustomer(user);
        } catch (err) {
            setError("Failed to load.");
        }
        setLoading(false);
    }

    const submit = async () => {
        if (!customer || !id) return;
        setUpdating(true);

        if (!check_passwords()) {
            setUpdating(false);
            return;
        }

        try {
            const res = await setOnboardingPassword(customer.id, id, password);
            setUser(res);
        } catch (err) {
            setError("Failed to setup your account.");
        }
        setUpdating(true);
    }

    useEffect(() => {
        if (id) load_user();
    }, [id]);

    useEffect(() => {
        // If we logged in
        if (user) {
            navigate("/dash/home");
        }
    }, [user]);


    if (loading) return (
        <div className="auth-loading">
            <InlineLoader color={accent_color} />
        </div>
    );

    return (
        <div className="onboard-view">
            <div className="onboard-view-form-con">
                <Logo />

                <div className="con">
                    <h3>Hey {customer?.first_name}, let's get started!</h3>
                    <p>Please enter your new password below.</p>
                </div>
                <FormLayout>
                    <Input type="password" label="New Password" value={password} onChange={setPassword} disabled={loading} />
                    <Input type="password" label="Confirm Password" value={confirm_password} onChange={setConfirmPassword} disabled={loading} />

                    <Button onClick={submit} loading={updating}>Complete Onboarding</Button>

                    {error && <div className="error">{error}</div>}
                </FormLayout>
            </div>
        </div>

    )
}