import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CustomerLayout } from "../layout/CustomerLayout";
import { LoginView } from "../views/auth/LoginView";
import { HomeView } from "../views/home/HomeView";
import { ViewProfileView } from "../views/profile/ViewProfileView";
import { ViewOrgView } from "../views/org/ViewOrgView";
import { CreateNewVpaOrderView } from "../views/order/CreateNewVpaOrderView";
import { SelectAgentMarketingOrderView } from "../views/order/create-am-order/SelectAmTypeView";
import { CreateNewSoldAgentMarketingOrderView } from "../views/order/create-am-order/CreateSoldAmView";
import { ViewOrdersView } from "../views/order/ViewOrdersView";
import { ViewOrderView } from "../views/order/ViewVpaOrderView";
import { ViewAmOrderView } from "../views/order/ViewAmOrderView";
import { CreateTestAmView } from "../views/order/create-am-order/CreateTestAmView";
import { CreateEcoAmView } from "../views/order/create-am-order/CreateEcoAmView";
import { OnboardingView } from "../views/auth/OnboardView";
import { ForgotPasswordView } from "../views/auth/ForgotPasswordView";
import { ResetPasswordView } from "../views/auth/ResetPasswordView";
import { AgentMarketingReportView } from "../views/reports/AmReportView";
import { CampaignReportView } from "../views/reports/VpaReportView";

export const RootRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<LoginView />} />
                <Route path="setup" element={<OnboardingView />} />
                <Route path="forgot-password" element={<ForgotPasswordView />} />2
                <Route path="reset-password" element={<ResetPasswordView />} />

                <Route path="dash" element={<CustomerLayout />}>
                    <Route path="home" element={<HomeView />} />
                    <Route path="profile" element={<ViewProfileView />} />
                    <Route path="org" element={<ViewOrgView />} />

                    <Route path="orders" element={<ViewOrdersView />} />
                    <Route path="orders/:id" element={<ViewOrderView />} />

                    <Route path="order-vpa-campaign" element={<CreateNewVpaOrderView />} />

                    <Route path="agent-marketing" element={<SelectAgentMarketingOrderView />} />
                    <Route path="agent-marketing/create-sold" element={<CreateNewSoldAgentMarketingOrderView />} />
                    <Route path="agent-marketing/create-test" element={<CreateTestAmView />} />
                    <Route path="agent-marketing/create-eco" element={<CreateEcoAmView />} />
                    <Route path="agent-marketing/:id" element={<ViewAmOrderView />} />
                </Route>

                <Route path="campaign-report/:id" element={<CampaignReportView />} />
                <Route path="agent-marketing-report/:id" element={<AgentMarketingReportView />} />

                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
};