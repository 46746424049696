import "../styles/upload-profile-picture.comp.scss"
import { UploadImage } from "./UploadImage"

export interface Props {
    onClick: (e: any) => void;
    image: string;
    disabled?: boolean;
}

export const UploadProfilePicture = (props: Props) => {
    return (
        <div className="profile-image-wrapper">
            <UploadImage label="" onClick={props.onClick} disabled={props.disabled}>
                <img className="profile_img" src={props.image} alt="profile" />
            </UploadImage>
        </div>
    )
}