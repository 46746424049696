
import "../styles/upload-image-mini.comp.scss";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useRef, useState } from "react";
import { CircleLoader, DotLoader } from "react-spinners";
import { ImageViewer } from "./ImageViewer";
import axios from "axios";
import { client } from "../api/api.config";
import { accent_color } from "../defs/color.def";

interface Props {
    image?: string;
    onChange: (image: string, error?: string) => void;
    onRemove?: (image: string) => void;
}

/**
 * This image uploader is generic and will dump images on the server in a media section.
 * @param props 
 * @returns 
 */
export const UploadImageMini = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLInputElement>(null!);
    const [vis, setVis] = useState(false);

    const handle_click = () => {
        if (props.image) {
            setVis(true);
        } else {
            if (ref) ref.current?.click();
        }
    }

    // TODO: When time permits make this a multiple image upload.
    const simulate_click = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e?.target?.files?.[0];
        if (!file) return;

        setLoading(true);

        // Try to upload the image
        try {
            const form = new FormData();
            form.append("image", file);

            // Make API call here
            const res = await client().post(`/order/am/media/create`, form);

            console.log('res', res);

            if (res.status === 201) {
                props.onChange(res.data?.url);
            }

        } catch (err) {
            console.error("Failed to upload the image.", err);
        }

        setLoading(false);
    }

    const handle_remove_click = (e: any) => {
        e.stopPropagation();

        if (props.image && props.onRemove) {
            props.onRemove(props.image);
        }
    }

    return (
        <>
            <div className="image-mini-wrapper" onClick={() => handle_click()}>
                {props.image && <div className="image-mini-image" style={{
                    backgroundImage: `url('${props.image}')`
                }} />}

                {(!props.image && !loading) && <div className="add-icon">
                    <FontAwesomeIcon icon={faPlus} />
                </div>}

                {props.image && <div className="remove-icon" onClick={handle_remove_click}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>}

                {loading &&
                    <div className="image-loading">
                        <DotLoader size={25} color={accent_color} />
                    </div>
                }

                <input
                    className="hidden-button"
                    type="file"
                    accept="image/*"
                    onChange={simulate_click}
                    multiple={false}
                    ref={ref}
                />

            </div>
            <ImageViewer image={props.image || ''} onClose={() => setVis(false)} visable={vis} />

        </>

    )

}