import "../styles/shutter-agency-list-item.comp.scss";
import { Agency } from "../defs/agency-model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export interface Props {
    open: boolean;
    agency: Agency;
    onClick: (agency: Agency) => void;
    onEdit?: (agency: Agency) => void;
    selected?: boolean;
}

export const ShutterAgencyListItem = (props: Props) => {
    return (
        <div className="agency-list-item" onClick={() => props.onClick(props.agency)} id={props.selected ? "selected" : "unselected"}>
            <div className="agency-list-item-logo" style={{
                backgroundImage: `url(${props.agency?.logo})`
            }} />

            <div className="agency-list-item-right">
                {props.open && (
                    <div className="agency-list-item-name">
                        {props.agency.name}
                    </div>)}
                {props.open && (
                    <div className="agency-list-item-edit" onClick={(e) => {
                        e.stopPropagation();
                        props.onEdit && props.onEdit(props.agency);
                    }}>
                        <FontAwesomeIcon icon={faEdit} />
                    </div>)}

            </div>
        </div>
    )
}