import '../styles/textarea.comp.scss';

interface Props {
    label?: string;
    type?: string;
    placeholder?: string;
    value: string;
    disabled?: boolean;
    onChange: (v: string) => void;
    onEnter?: () => void;
    rows?: number;
}

export const Textarea = (props: Props) => {
    // Listen for enter events
    const onEnter = (e: any) => {
        if (e.key === 'Enter') {
            if (props.onEnter) {
                props.onEnter();
            }
        }
    };

    return (
        <div className="textarea-wrapper">
            {props.label && <label>{props.label}</label>}

            <textarea
                onKeyDown={onEnter}
                placeholder={props.placeholder || ''}
                value={props.value}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e.target.value)}
                rows={props.rows || 1}
            ></textarea>
        </div>
    );
};
