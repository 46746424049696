import "../../styles/campaign-report-view.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Agency } from "../../defs/agency-model";
import { convert_type, getOrderViaReportId } from "../../api/order.api";
import { SecondaryButton } from "../../comps/SecondaryButton";
import { InlineLoader } from "../../comps/InlineLoader";
import { CampaignReport } from "../../comps/CampaignReport";
import { Button } from "../../comps/Button";

export const AgentMarketingReportView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const [error, setError] = useState("");
    const [order, setOrder] = useState<Partial<any>>(null!);
    const [agency, setAgency] = useState<Agency>(null!);
    const [agent, setAgent] = useState<any>(null!);

    // Determine if we need the header or not
    const queryParams = new URLSearchParams(location.search);
    const no_header = queryParams.get("no-header") !== "true";

    // This function is for the user to click the copy link button so they can share the report with others
    const copy_link = () => {
        if (!navigator) return;
        navigator.clipboard.writeText(`${window.location.href}?no-header=true`);
    }

    // Load the agent marketing report
    const load = async () => {
        if (!id) return;

        try {
            const res = await getOrderViaReportId(id);
            console.log("Got the res: ", res);
            setOrder(res);
            setAgency(res?.team?.agency || {});
            setAgent(res?.team?.lead_customer || {});
        } catch (err) {
            console.error("Failed to load the campaing.");
            setError("Failed to load the campaign.");
        }
    }

    // This function is for getting the header of the report
    const get_header = () => {
        if (order?.type === "sold") return order.address;
        return `${convert_type(order?.type)} Agent Marketing Campaign`;
    }

    useEffect(() => {
        load();
    }, [id]);

    return (
        <div className="campaign-report-view" id={!no_header ? "no-header" : ""}>
            {no_header && (
                <div className="campaign-report-header">
                    <div className="back" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="controls">
                        <SecondaryButton onClick={() => copy_link()}><FontAwesomeIcon icon={faCopy} />{" "} Copy Link </SecondaryButton>
                        {/* <SecondaryButton onClick={() => download()}><FontAwesomeIcon icon={faFileDownload} />{" "} Download Report</SecondaryButton> */}
                    </div>
                </div>
            )}

            <div className="campaign-report-inner">
                {!order || !id ? (
                    <div className="loading">
                        <InlineLoader color="#232323" />
                    </div>
                ) : (
                    <CampaignReport id={order.id || 0} facebook_ad_id={order?.facebook_ad_id || ""} agent_name={agent ? `${agent.first_name} ${agent.last_name}` : undefined} report_id={id} address={get_header() || ""} header_img={order?.am_order?.amOrderMedia?.[0]?.url || ""} agency_name={agency?.name || ""} agency_logo={agency?.logo || ""} />
                )}
            </div>

            {error && <div className="error">
                {error}
            </div>}
        </div>
    )
}   