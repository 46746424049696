
import "../styles/modal.layout.scss"
import { ReactNode } from "react"

// For creating modals
export const ModalLayout = ({ children }: { children: ReactNode }) => {
    return (
        <div className="modal-content-view">
            {children}
        </div>
    )
}