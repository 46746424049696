import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import "../styles/line-chart.comp.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResponsiveContainer, AreaChart, Area, Tooltip, YAxis, XAxis } from "recharts";
import { useEffect, useState } from "react";
import { add_commas } from "../utils/numbers";
import { InlineLoader } from "./InlineLoader";
import { ChartTooltip } from "./ChartTooltip";

interface Props {
    data: any[];
    title: string;
    total: number;
    icon: IconDefinition;
    loading: boolean;
}

export const LineChart = (props: Props) => {
    const [key, setKey] = useState('');

    useEffect(() => {
        if (!props.data || !props.data[0]) return;
        setKey(Object.keys(props.data[0])[0]);
    }, [props.data])

    return (
        <div className="chart-area-card">
            <div className="chart-area-card-header">
                <div className="chart-area-card-icon">
                    <FontAwesomeIcon icon={props.icon} />
                </div>
                <div>
                    <div className="chart-area-total">
                        {add_commas(props.total || 0)}
                    </div>
                    <h3>{props.title}</h3>
                </div>
            </div>

            {(!props.data || props.data?.length <= 0) || props.loading && (<div className="chart-area-card-loader">
                <InlineLoader />
            </div>)}

            {!props.loading &&
                <div className="chart-area-wrapper">
                    {props.data && props.data?.length > 0 ? (
                        <ResponsiveContainer width="100%" height={250}>
                            <AreaChart style={{
                                width: "100%"
                            }} data={props.data}
                                margin={{ top: 10, right: -3, left: -3, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#dadad2" stopOpacity={0.6} />
                                        <stop offset="95%" stopColor="#dadad2" stopOpacity={0} />
                                    </linearGradient>
                                </defs>

                                <Tooltip content={<ChartTooltip dataKey={key} />} />
                                <Area
                                    type="monotone" dataKey={key} stroke="#dadad2" strokeWidth={4} fillOpacity={1} fill="url(#colorUv)"
                                    isAnimationActive={false}
                                />
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : (
                        <div className="chart-area-no-results">
                            <p>No results.</p>
                        </div>
                    )}
                </div>
            }
        </div >
    )
}