import { useEffect, useState } from "react"
import { Input } from "../../comps/Input"
import { ViewHeader } from "../../comps/ViewHeader"
import { FormLayout } from "../../layout/FormLayout"
import { TwoColumn } from "../../layout/TwoColumn"
import { View } from "../../layout/View"
import { useUserStore } from "../../stores/user-store"
import { fetchCustomer, updateCustomer } from "../../api/customer.api"
import { Customer } from "../../defs/customer-model"
import { Warning } from "../../comps/Warning"
import { Button } from "../../comps/Button"
import { UploadImage } from "../../comps/UploadImage"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { UploadProfilePicture } from "../../comps/UploadProfilePicture"
import { client } from "../../api/api.config"
import { Body } from "../../layout/BodyLayout"

export const ViewProfileView = () => {
    const user = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);
    const [customer, setCustomer] = useState<Customer>(null!);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");

    const update = (key: string, value: any) => {
        setCustomer({
            ...customer,
            [key]: value
        });
    }

    const load_customer = async () => {
        setLoading(true);
        setError("");
        try {
            const customer = await fetchCustomer(user?.customer?.id);
            setCustomer(customer);
            setUser({ ...user, customer });
        } catch (err) {
            setError("Failed to get your details.");
        }
        setLoading(false);
    }

    const update_customer = async () => {
        setSaving(true);
        setError("");
        try {
            const res = await updateCustomer(customer);
            load_customer();
        } catch (err) {
            setError("Failed to update your details.");
        }
        setSaving(false);
    }

    // Handle updating the profile picture
    const update_dp = async (e: any) => {
        const file = e?.target?.files?.[0];
        if (!file) return;
        setSaving(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await client().post(`/customer/${customer?.id}/dp`, form);
            if (res.status === 200) {
                update("dp", res.data.dp)
                setUser({ ...user, customer: { ...user.customer, dp: res.data.dp } });
            }
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setSaving(false);
    }

    useEffect(() => {
        if (user) load_customer();
    }, [])

    return (
        <View>
            <ViewHeader back title="Account Details" />

            <Body>
                <Warning type={"info"} title={"User Information"}>
                    <p>Once you have made your changes be sure to click submit to confirm your changes.</p>
                </Warning>

                <FormLayout>
                    <UploadProfilePicture onClick={update_dp} image={customer?.dp || `https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=404e3d&color=fff&size=128`} />
                </FormLayout>

                <FormLayout loading={loading}>
                    <div className="form-flex-2">
                        <Input label="First Name" value={customer?.first_name} onChange={(v: string) => update("first_name", v)} />
                        <Input label="Last Name" value={customer?.last_name} onChange={(v: string) => update("last_name", v)} />
                    </div>
                    <Input label="Email" value={customer?.email} onChange={(v: string) => update("email", v)} />
                    <Input label="Mobile" value={customer?.mobile || ""} onChange={(v: string) => update("mobile", v)} />
                    <br />
                    <Button loading-={saving} onClick={() => update_customer()}>
                        Confirm Changes
                    </Button>
                </FormLayout>

            </Body>
        </View>
    )
}