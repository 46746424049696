import "../styles/status-bar.comp.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderStatus } from "../defs/order-model";
import { capitalize } from "lodash";
import { Tooltip } from "react-tooltip";

export interface Status {
    color: string;
    icon: IconDefinition;
    name: OrderStatus;
    tooltip?: string;
}

interface Props {
    statuses: Status[];
    status: OrderStatus; // Where we are at
    start_date?: Date;
    end_date?: Date;
}

export const StatusBar = (props: Props) => {
    const get_status_width = () => {
        const index = props.statuses.findIndex(status => status.name === capitalize(props.status));
        const increment = 100 / (props.statuses.length - 1);
        if (index === 0 || index === -1) return "5%";
        return `${increment * index}%`;
    }

    return (
        <div className="status-bar-wrapper">
            <div className="status-bar-labels">
                {props.statuses.map((status: Status, i: number) => (
                    <div className="status-bar-label" key={i} data-tooltip-id="status-tooltip" data-tooltip-content={status.tooltip ? status.tooltip : ""}>

                        <div className="status-icon">
                            {status.icon && <FontAwesomeIcon icon={status.icon} />}
                        </div>
                        <div className="status-name">{status.name}</div>
                        <Tooltip id="status-tooltip" place="bottom" style={{ maxWidth: 250, backgroundColor: "#232323", color: "#fff", zIndex: 10000 }} />
                    </div>
                ))}
            </div>

            <div className="status-bar">
                <div className="status-bar-fill" style={{ width: get_status_width() }} />
            </div>
        </div >
    )
}