import "../../styles/login.view.scss";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import { useUserStore } from "../../stores/user-store";
import { login } from "../../api/auth.api";
import { Logo } from "../../comps/Logo";
import { Input } from "../../comps/Input";
import { Button } from "../../comps/Button";
import { CopyBar } from "../../comps/CopyBar";
import { Error } from "../../comps/Error"

export const LoginView = () => {
    const navigate = useNavigate();
    const user = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    // Try to sign in 
    const sign_in = async () => {
        // Reset the form
        setError("");
        setUser(null);
        setLoading(true);

        // Login the user
        const user = await login(email, password, setError);
        if (!user) setError("Failed to login the user.")
        setUser(user);

        // Reset the form
        setEmail("");
        setPassword("");
        setLoading(false);
    }

    // Watch the user, if it changes then we can proceed to the user
    useEffect(() => {
        // If we logged in
        if (user) {
            navigate("/dash/home");
        }
    }, [user]);

    return (
        <>
            <div className="login-view">
                <div className="login-view-form-con">
                    <Logo />

                    <FormLayout>
                        <Input label="Email" value={email} onChange={(v) => setEmail(v)} disabled={loading} onEnter={() => sign_in()} />
                        <Input label="Password" value={password} onChange={(v) => setPassword(v)} type="password" disabled={loading} onEnter={() => sign_in()} />
                        <Button onClick={() => sign_in()} loading={loading}>Sign in</Button>
                        {error && <Error>{error}</Error>}
                        <Link to="/forgot-password">Forgot password?</Link>
                    </FormLayout>
                </div>

                <div className="login-view-image"
                    style={{
                        backgroundImage: "url('/img/main.jpg')",
                    }}
                />
            </div>
            <CopyBar />
        </>
    )
}