/**
 * This function is used for debouncing network requests
 * 
 * Pass a function and the desired delay time between requests
 */
export const debounce = (func: any, delay: number) => {
    let timeoutId: any;

    return function debounced(...args: any[]) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};
