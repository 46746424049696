const auto_complete_config = {
    types: ['address'],
    region: 'au',
};

export interface Coords {
    lat: number;
    lng: number;
}

/**
 * This function gets the suggestions from the Google Places API
 */
export const fetchSuggestions = async (query: string): Promise<string[]> => {
    // CHeck if GOogle is loaded into the window
    if (!(window as any)?.google) {
        throw new Error('Google API is not attatched to window');
    }

    const complete_service = new (
        window as any
    ).google.maps.places.AutocompleteService();

    return new Promise((res, rej) => {
        const request = {
            ...auto_complete_config,
            input: query,
        };

        complete_service.getPlacePredictions(
            request,
            (results: any, status: any) => {
                if (
                    status !==
                    (window as any)?.google.maps.places.PlacesServiceStatus.OK
                ) {
                    rej(status);
                } else {
                    res(results.map((result: any) => result.description));
                }
            }
        );
    });
};

// Geocode an address
export const geocodeAddress = async (address: string): Promise<Coords> => {
    // Check if  Google is loaded into the window
    if (!(window as any)?.google) {
        console.log('Google API is not loaded.');
        return { lat: 0, lng: 0 };
    }

    // Make the query to Google Places API
    const geocoder = new (window as any).google.maps.Geocoder();

    // Return the results
    return new Promise((resolve, reject) => {
        // Make the request
        geocoder.geocode({ address }, (results: any, status: any) => {
            if (status !== 'OK') {
                reject(status);
            }

            // Get the lat and lng
            const { lat, lng } = results[0].geometry.location;

            resolve({
                lat: lat(),
                lng: lng(),
            });
        });
    });
};
