import { faAward, faComments, faSignHanging } from "@fortawesome/free-solid-svg-icons";
import { AmType, NewAmOrder, NewVpaOrder, Order, OrderStatus, OrderType } from "../defs/order-model";
import { client } from "./api.config";

// Get the Orders for the View Campaiggns view 
export const getOrdersForViewCampaigns = async (query: string, filters: any, sort: any) => {
    const res = await client().get(`/customer/${1}/order?query=${query}&filters=${JSON.stringify(filters)}&sort=${JSON.stringify(sort)}`);
    return res.data;
}


// Get a vpa order
export const getOrder = async (order_id: number) => {
    const res = await client().get(`/order/${order_id}`);
    return res.data;
}

// Create a new vpa order
export const createVpaOrder = async (new_order: NewVpaOrder) => {
    const res = await client().post("/order/vpa", {
        ...new_order,
        package_id: new_order.package?.id,
        team_id: new_order.team?.id,
    });

    return res.data;
}

// Create a new am order
export const createAmOrder = async (new_order: NewAmOrder) => {
    new_order.budget = new_order.budget * 100; // Converting the dollar amount to cents
    const res = await client().post("/order/am", new_order);
    return res.data;
}

// Get all the orders for a customer
export const getCustomersOrders = async (query: string, filters: any, sort: string | null, page: number) => {
    const res = await client().get(`/customer/${1}/order?query=${query}&page=${page}&filters=${JSON.stringify(filters)}&sort=${JSON.stringify(sort)}`);
    return res.data;
}

// Helper function for generating the type
export const convert_type = (type: AmType) => {
    switch (type) {
        case AmType.SOLD:
            return "Property Sold";
        case AmType.TEST:
            return "Testimonial";
        case AmType.ECO:
            return "Ecosystem"
        default:
            return ""
    }
}

export const AGENT_MARKETING_DROPDOWN_OPTIONS = [{
    label: "Sold Campaign",
    value: AmType.SOLD
},
{
    label: "Testimonial Campaign",
    value: AmType.TEST
},
{
    label: "Ecosystem Campaign",
    value: AmType.ECO
}];

// Get the option where value === option.value
export const get_agent_marketing_option = (value: AmType) => {
    return AGENT_MARKETING_DROPDOWN_OPTIONS?.find((opt: any) => opt.value === value);
}

// Return the Icon for the agent marketing type
export const get_agent_marketing_type_icon = (type: AmType) => {
    switch (type) {
        case AmType.SOLD:
            return faSignHanging;
        case AmType.TEST:
            return faComments;
        case AmType.ECO:
            return faAward;
        default:
            return faAward;
    }
}

// Handle adding an image to an agent marketing order
export const uploadSingleAmImage = async (order_id: number, url: string) => {
    const res = await client().post(`/order/am/${order_id}/media`, { url });
    return res.data;
}

// handle removing an image from an agent marketing order
export const removeSingleAmImage = async (order_id: number, media_id: number) => {
    const res = await client().delete(`/order/am/${order_id}/media/${media_id}`);
    return res.data;
}

// Get AM order via it's report id
export const getOrderViaReportId = async (report_id: string) => {
    const res = await client().get(`/order/report/${report_id}`);
    return res.data;
}


// Generate the OrderStatus text
export const generateOrderStatusText = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.ACTIVE:
            return "Running";
        case OrderStatus.CANCELLED:
            return "Cancelled";
        case OrderStatus.COMPLETED:
            return "Completed";
        case OrderStatus.PENDING:
            return "Pending";
        default:
            return "N/A";
    }
}

// Generate the OrderStatus colour
export const generateOrderStatusColor = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.ACTIVE:
            return "#67a860";
        case OrderStatus.CANCELLED:
            return "#c74a4a";
        case OrderStatus.COMPLETED:
            return "#4aa8c7";
        case OrderStatus.PENDING:
            return "#fcba03";
        default:
            return "#696969";
    }
}

export const generateOrderStatusColorFromText = (text: string) => {
    switch (text) {
        case "Running":
            return "#67a860";
        case "Cancelled":
            return "#c74a4a";
        case "Completed":
            return "#4aa8c7";
        case "Pending":
            return "#fcba03";
        default:
            return "#696969";
    }
}

export const generateOrderStatusBgColorFromText = (text: string) => {
    switch (text) {
        case "Running":
            return "#eaf7ea";
        case "Cancelled":
            return "#f7eaea";
        case "Completed":
            return "#eaf2f7";
        case "Pending":
            return "#f7f5ea";
        default:
            return "#f7f7f7";
    }

}


// Render the orders for table view
export const renderOrdersForTable = (orders: Order[]) => {
    let new_orders = [] as any[];

    for (const order of orders) {
        let new_order = {} as any;

        if (order?.order_type === OrderType.VPA) {
            new_order.address = order?.vpa_order?.address || "N/A";
            new_order.start_date = order?.vpa_order?.start_date || "N/A";
            new_order.end_date = order?.vpa_order?.end_date || "N/A";
            new_order.package = order?.vpa_order?.pack?.name || "N/A";
            new_order.type = "VPA";
        } else if (order?.order_type === OrderType.AM) {
            new_order.address = order?.am_order?.address || "N/A";
            new_order.start_date = order?.am_order?.date || "N/A";
            new_order.end_date = "N/A";
            new_order.package = convert_type(order?.am_order?.type) || "N/A";
            new_order.type = "AM";
        }

        // new_order.status.label = generateOrderStatusText(order?.status);
        // new_order.status.color = generateOrderStatusColor(order?.status);
        console.log("Got the status: ", order?.status);
        new_order.status = generateOrderStatusText(order?.status);
        new_order.agency = order?.team?.agency?.name || "N/A";
        new_order.lead_agent = order?.team?.lead_customer ? `${order?.team?.lead_customer?.first_name} ${order?.team?.lead_customer?.last_name}` : "N/A";
        new_order.id = order?.id;
        new_order.order_type = order?.order_type;

        new_orders.push(new_order);
    }

    return new_orders;
}