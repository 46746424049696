import "../../styles/campaign-report.view.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy, faFileDownload, faShare } from "@fortawesome/free-solid-svg-icons";
import { Order } from "../../defs/order-model";
import { Agency } from "../../defs/agency-model";
import { getOrderViaReportId } from "../../api/order.api";
import { SecondaryButton } from "../../comps/SecondaryButton";
import { InlineLoader } from "../../comps/InlineLoader";
import { CampaignReport } from "../../comps/CampaignReport";


export const CampaignReportView = () => {
    // Get the id from the params
    const { id } = useParams<{ id: string }>();
    const [error, setError] = useState("");
    const [order, setOrder] = useState<Partial<Order>>(null!);
    const [agency, setAgency] = useState<Agency>(null!);
    const [agent, setAgent] = useState<any>(null!);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Get the query parameter no-header as a boolean
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const no_header = queryParams.get("no-header") !== "true";

    // const download = async () => {
    //     if (!id) return;

    //     const toast_id = toast.loading('Downloading report...');

    //     try {
    //         const pdf = await download_report(id);

    //         // Create a blob URL for the PDF
    //         const blob = new Blob([pdf], { type: 'application/pdf' });
    //         const url = URL.createObjectURL(blob);

    //         // Create a temporary link element
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = `${order.address}-report.pdf`;

    //         // Trigger the download
    //         document.body.appendChild(link);
    //         link.click();

    //         // Clean up
    //         document.body.removeChild(link);
    //         URL.revokeObjectURL(url);

    //         toast.success('Report downloaded successfully!', { id: toast_id });
    //     } catch (err) {
    //         toast.error('Failed to download report!', { id: toast_id });
    //         console.error("Failed to download the report: ", err);
    //         setError("Failed to download the report.");
    //     }
    // }

    // Load the campaign report which is just a partial order. 
    const load = async () => {
        setLoading(true);
        if (!id) return;
        try {
            const data = await getOrderViaReportId(id);
            if (!data) throw new Error("Failed to get the order.");

            console.log("Got the data: ", data);

            setOrder(data);
            setAgency(data?.team?.agency || {});
            setAgent(data?.team?.lead_customer || {});
            // setOrder(order);
        } catch (err) {
            console.error("Failed to load the order: ", err);
            setError("Failed to retrieve the campaign report.")
        }
        setLoading(false);
    }

    // Generates a shareable link that anyone can view. Then copies to clipboard
    const copy_link = () => {
        if (!navigator) return;
        navigator.clipboard.writeText(`${window.location.href}?no-header=true`);
        // toast.success("Copied!");
    }

    useEffect(() => {
        if (id) load();
    }, [id]);


    return (
        <div className="campaign-report-view" id={!no_header ? "no-header" : ""}>
            {no_header && (
                <div className="campaign-report-header">
                    <div className="back" onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="controls">
                        <SecondaryButton onClick={() => copy_link()}><FontAwesomeIcon icon={faCopy} />{" "} Copy Link </SecondaryButton>
                        {/* <SecondaryButton onClick={() => download()}><FontAwesomeIcon icon={faFileDownload} />{" "} Download Report</SecondaryButton> */}
                    </div>
                </div>
            )}

            <div className="campaign-report-inner">
                {!order || !id ? (<div className="loading">
                    <InlineLoader color="#232323" />
                </div>) : (
                    <CampaignReport id={order.id || 0} agent_name={agent ? `${agent.first_name} ${agent.last_name}` : undefined} facebook_ad_id={order?.facebook_ad_id || ""} report_id={id} address={order?.vpa_order?.address || ""} header_img={order?.vpa_order?.header_image || ""} agency_name={agency?.name || ""} agency_logo={agency?.corporation?.logo || agency?.logo || ""} />
                )}
            </div>

            {error && <div className="error">
                {error}
            </div>}

            {!loading && <div id="loaded" />}
        </div>
    )

}