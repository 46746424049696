import "../styles/add-customer-modal.scss";
import { useState } from "react"
import { ModalLayout } from "./ModalLayout"
import { Agency } from "../defs/agency-model";
import { createCustomer } from "../api/customer.api";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { Option, Select } from "./Select";
import { Button } from "./Button";
import { Role, select_roles } from "../defs/customer-model";

interface Props {
    agencies: Agency[];
    onAdd: () => void;
}

export const AddCustomerModal = (props: Props) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<Role>(null!);
    const [selected_agency, setSelectedAgency] = useState<Agency | null>(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const add = async () => {
        if (!selected_agency) return;

        setLoading(true);
        setError('');

        try {
            await createCustomer({ first_name, last_name, email, agency_id: selected_agency.id, role: role });
            props.onAdd();
        } catch (err) {
            setError("Failed to create agent");
        }

        setLoading(false);
    }

    // handle selecting the agency
    const selectAgency = (agency: Agency) => {
        // If it is already selected, unselect it
        if (selected_agency?.id === agency.id) setSelectedAgency(null!);
        else setSelectedAgency(agency);
    }

    return (
        <ModalLayout>
            {/* Select Agency */}
            <label>Select Agency</label>
            <div className="agency-list-select">
                {props.agencies.map((agency) => (
                    <div
                        key={agency.id}
                        className={`agency-list-item ${agency.id === selected_agency?.id ? "selected" : ""}`}
                        onClick={() => selectAgency(agency)}
                    >
                        {agency.name}
                    </div>
                ))}
            </div>

            <FormLayout>
                <Input type="text" label="First Name" value={first_name} onChange={setFirstName} />
                <Input type="text" label="Last Name" value={last_name} onChange={setLastName} />
                <Input type="text" label="Email" value={email} onChange={setEmail} />
                <Select label="Role" value={select_roles?.find((o: Option) => o.value === role)} options={select_roles} onChange={(o: Option) => setRole(o.value)} />

                <br />

                <Button onClick={add} loading={loading}>Create Staff!</Button>
            </FormLayout>

        </ModalLayout>
    )
}