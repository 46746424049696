import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PackageOption } from "../defs/order-model"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"

export const PackageCardOption = ({
    option
}: { option: PackageOption }) => {
    return (
        <div className="package-option">
            {option.checked ? (
                <FontAwesomeIcon icon={faCheck} />
            ) : (
                <FontAwesomeIcon icon={faTimes} />
            )}
            <div>{option.name}</div>
        </div>
    )
}