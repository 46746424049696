import "../styles/modal-corp-layout.scss";
import { useEffect, useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { Agency } from "../defs/agency-model";
import { fetchAgency, updateAgency, updateAgencyLogo } from "../api/agency.api";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { InlineLoader } from "./InlineLoader";
import { UploadProfilePicture } from "./UploadProfilePicture";
import { Text } from "./Text";
import { Button } from "./Button";

interface Props {
    id: number;
    edit?: boolean;
}

export const EditViewAgencyModal = (props: Props) => {
    const [data, setData] = useState<Agency>(null!);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");

    // Update the data locally
    const updateLocal = (key: string, value: any) => {
        setData((prev) => {
            return {
                ...prev,
                [key]: value
            }
        });
    }

    // Update the data
    const updateData = async () => {
        setUpdating(true);
        setError('');

        try {
            await updateAgency(props.id, data);
        } catch (err) {
            setError("Failed to update the Agency...");
        }

        setUpdating(false);
    }

    // Update the logo
    const updateLogo = async (e: any) => {
        const file = e?.target?.files?.[0];
        if (!file) return;
        setUpdating(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await updateAgencyLogo(props.id, form);
            console.log("Got the logo: ", res);
            updateLocal("logo", res.logo)
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setUpdating(false);
    }

    // Load the data
    const loadData = async () => {
        setError("");
        setLoading(true);

        try {
            const temp = await fetchAgency(props.id);
            setData(temp);
        } catch (err) {
            setError("Failed to load the Agency...");
        }
    }

    useEffect(() => {
        loadData();
    }, [props.id]);

    return (
        <ModalLayout>
            {loading && (<InlineLoader color="" />)}

            {props.edit ? (
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={data?.logo} />
                    <Input label="Name" value={data?.name} onChange={(v: string) => updateLocal("name", v)} />
                    <Input label="Address" value={data?.address} onChange={(v: string) => updateLocal("address", v)} />
                    <br />
                    <Button onClick={updateData} loading={updating}>Confirm Changes</Button>
                </FormLayout>
            ) : (
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={data?.logo} disabled />
                    <Text label="Name" value={data?.name} />
                    <Text label="Address" value={data?.address} />
                </FormLayout>
            )}
        </ModalLayout>
    );
}