import { useEffect, useState } from "react"
import { Serachbar } from "../../comps/Searchbar"
import { ViewHeader } from "../../comps/ViewHeader"
import { View } from "../../layout/View"
import { Order, OrderType } from "../../defs/order-model"
import { generateOrderStatusBgColorFromText, generateOrderStatusColorFromText, getCustomersOrders, getOrdersForViewCampaigns, renderOrdersForTable } from "../../api/order.api"
import { List } from "../../layout/ListLayout"
import { OrderListItem } from "../../comps/OrderListItem"
import { FilterBarLayout } from "../../layout/FilterBarLayout"
import { Button } from "../../comps/Button"
import { ReactSelectWrapper } from "../../comps/ReactSelectWrapper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox, faBuilding, faCalendar, faHouse, faMagnifyingGlass, faPerson, faTimeline } from "@fortawesome/free-solid-svg-icons"
import { Sort, Table, TableDate, TableDateTooltipRender, TableText } from "../../comps/Table"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

export const ViewOrdersView = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [orders, setOrders] = useState<Order[]>([])
    const [page, setPage] = useState<number>(0);
    const [end, setEnd] = useState<boolean>(false);

    const [campaign_type_filter, setCampaignTypeFilter] = useState<any>(null!);
    const [status_filters, setStatusFilters] = useState<any[]>([]);
    const [sort, setSort] = useState<Sort>({
        key: "pack",
        direction: "desc"
    });

    const handle_search = async (query: string) => {
        setLoading(true);
        try {
            const filters = {
                statuses: status_filters.length > 0 ? status_filters.map((f) => f.value) : undefined,
                campaign_type: campaign_type_filter ? campaign_type_filter.value : undefined
            }

            const res = await getOrdersForViewCampaigns(query, filters, sort);
            if (res.length === 0) setEnd(true);

            setOrders(renderOrdersForTable(res));
        } catch (err) {

        }
        setLoading(false);
    }

    const open_row = (row: any) => {
        if (!row.id) return;

        console.log("Got the order type: ", row.order_type);

        if (row.order_type === OrderType.AM) {
            navigate(`/dash/agent-marketing/${row.id}`);
            return;
        } else {
            navigate(`/dash/orders/${row.id}`);
            return;
        }
    }

    useEffect(() => {
        handle_search(query);
    }, []);

    useEffect(() => {
        if (query === "") handle_search(query);
    }, [query])

    useEffect(() => {
        handle_search(query);
    }, [sort])

    return (
        <View>
            <ViewHeader title="Current Campaigns" back />
            <div className="result-header">
                <Serachbar label="Search Address" query={query} setQuery={setQuery} onSearch={handle_search} loading={false} no_search_button />
                <FilterBarLayout>
                    <ReactSelectWrapper label="Campaign Type" options={type_filters} value={campaign_type_filter} onChange={setCampaignTypeFilter} />
                    <ReactSelectWrapper isClearable isMulti label="Status" options={status_filters_options} value={status_filters} onChange={setStatusFilters} />
                </FilterBarLayout>
                <Button onClick={() => handle_search(query)}>
                    Search
                </Button>
            </div>
            <Table data={orders} loading={loading} columns={OrderColumns} onClick={open_row} sort={sort} onSort={setSort} />
            {/* <List loading={loading}> NOTE: If this is put back then we need to set the 
                {orders.map((order: Order) => (
                    <OrderListItem order={order} />
                ))}
            </List> */}
        </View>
    )
}

const status_filters_options = [
    {
        value: "ACTIVE",
        label: "Running"
    },
    {
        value: "PENDING",
        label: "Pending"
    },
    {
        value: "COMPLETED",
        label: "Completed"
    },
    {
        value: "CANCELLED",
        label: "Cancelled"
    }
];

const type_filters = [
    {
        value: "VPA",
        label: "VPA Campaign"
    },
    {
        value: "AM",
        label: "Agent Marketing"
    }

]

const sort_options = [
    {
        value: "status",
        label: "Status"
    },
    {
        value: "status_reverse",
        label: "Status (Reverse)"
    },
    {
        value: "created_at",
        label: "Date (Newest)"
    },
    {
        value: "created_at_reverse",
        label: "Date (Oldest)"
    }
]

const OrderColumns = [
    {
        title: "Address",
        key: "address",
        render: TableText,
        width: "2"
    },
    {
        title: "Agency",
        key: "agency",
        render: TableText
    },
    {
        title: "Lead Agent",
        key: "lead_agent",
        render: TableText
    },
    {
        title: "Start Date",
        key: "start_date",
        render: TableDate,
        tooltip_render: TableDateTooltipRender
    },
    {
        title: "End Date",
        key: "end_date",
        render: TableDate,
        tooltip_render: TableDateTooltipRender
    },
    {
        title: "Package",
        key: "package",
        render: TableText
    },
    {
        title: "Status",
        key: "status",
        render: (data: string) => <div className="v-t-text" style={{
            color: generateOrderStatusColorFromText(data),
            backgroundColor: generateOrderStatusBgColorFromText(data),
            fontWeight: "bold",
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            width: "fit-content ",
            borderRadius: 5
        }}> {data}</div >
    }
]