export enum Role {
    CORP_ADMIN = 'CORP_ADMIN',
    AGENCY_ADMIN = 'AGENCY_ADMIN',
    AGENT = 'AGENT',
    ASSISTANT = 'ASSISTANT',
    VIEWER = 'VIEWER'
};

export const select_roles = [
    {
        label: 'Corporate Admin',
        value: Role.CORP_ADMIN
    },
    {
        label: 'Agency Admin',
        value: Role.AGENCY_ADMIN
    },
    {
        label: 'Agent',
        value: Role.AGENT
    },
    {
        label: 'Assistant',
        value: Role.ASSISTANT
    },
    // {
    //     label: 'VIEWER',
    //     value: Role.VIEWER
    // }
]

export interface Customer {
    id: number;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    created_at: Date;
    updated_at: Date;
    last_sign_in: Date | null;
    dp: string | null;
    mobile: string | null;
    agency_id: number;
    role: Role;
    teams: any;
}