import '../styles/dropdown-menu.comp.scss';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

export interface DropdownItem {
    label: string;
    onClick?: any;
    icon?: IconDefinition;
    selected?: boolean;
}

interface Props {
    items: DropdownItem[];
}

export const DropdownMenu = (props: Props) => {
    const [open, setOpen] = useState(false);
    // const [clicked, setClicked] = useState(false);

    // Render the component
    return (
        <div
            className="drop-down-menu-wrapper"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        // onClick={() => setClicked(!clicked)}
        >
            {/* Wrap the component for hover */}
            {open && (
                <>
                    <div className="hover-wrapper"></div>
                    <div className="hover-helper"></div>
                </>
            )}
            {/* Render the options */}
            <AnimatePresence>
                {open && (
                    <motion.div
                        className="menu"
                        initial={{ y: -10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ type: 'spring', duration: 0.2 }}
                    >
                        {props.items.map((item, i) => (
                            <div
                                key={i}
                                className="item"
                                onClick={() => item.onClick()}
                            >
                                {item.icon && (
                                    <div className="icon">
                                        <FontAwesomeIcon icon={item.icon} />
                                    </div>
                                )}
                                <div className="label">{item.label}</div>
                            </div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
