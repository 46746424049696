import "../styles/modal-corp-layout.scss";
import { useEffect, useState } from "react";
import { ModalLayout } from "./ModalLayout";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { InlineLoader } from "./InlineLoader";
import { UploadProfilePicture } from "./UploadProfilePicture";
import { Text } from "./Text";
import { Button } from "./Button";
import { Customer } from "../defs/customer-model";
import { fetchCustomer, updateCustomer, updateCustomerDisplayPicture } from "../api/customer.api";

interface Props {
    id: number;
    edit?: boolean;
}

export const EditViewCustomerModal = (props: Props) => {
    const [data, setData] = useState<Customer>(null!);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");

    // Update the data locally
    const updateLocal = (key: string, value: any) => {
        setData((prev) => {
            return {
                ...prev,
                [key]: value
            }
        });
    }

    // Update the data
    const updateData = async () => {
        setUpdating(true);
        setError('');

        try {
            await updateCustomer(data);
        } catch (err) {
            setError("Failed to update the Agency...");
        }

        setUpdating(false);
    }

    // Update the logo
    const updateLogo = async (e: any) => {
        const file = e?.target?.files?.[0];
        if (!file) return;
        setUpdating(true);
        setError("");
        try {
            const form = new FormData();
            form.append("image", file);
            const res = await updateCustomerDisplayPicture(props.id, form);
            updateLocal("dp", res?.dp)
        } catch (err) {
            setError("Failed to upload the image.");
        }
        setUpdating(false);
    }

    // Load the data
    const loadData = async () => {
        setError("");
        setLoading(true);

        try {
            const temp = await fetchCustomer(props.id);
            setData(temp);
        } catch (err) {
            setError("Failed to load the Agency...");
        }
    }

    useEffect(() => {
        loadData();
    }, [props.id]);

    return (
        <ModalLayout>
            {loading && (<InlineLoader color="" />)}

            {props.edit ? (
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={data?.dp || ''} />
                    <Input label="First Name" value={data?.first_name} onChange={(v: string) => updateLocal("first_name", v)} />
                    <Input label="Last Name" value={data?.last_name} onChange={(v: string) => updateLocal("last_name", v)} />
                    <Input label="Email" value={data?.email} onChange={(v: string) => updateLocal("email", v)} />
                    <Input label="Mobile" value={data?.mobile || ""} onChange={(v: string) => updateLocal("mobile", v)} />

                    <br />
                    <Button onClick={updateData} loading={updating}>Confirm Changes</Button>
                </FormLayout>
            ) : (
                <FormLayout>
                    <UploadProfilePicture onClick={updateLogo} image={data?.dp || ""} disabled />
                    <Text label="First Name" value={data?.first_name} />
                    <Text label="Last Name" value={data?.last_name} />
                </FormLayout>
            )}
        </ModalLayout>
    );
}