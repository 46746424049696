import "../../../styles/select-dates.create-order.scss";
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { Button } from '../../../comps/Button';
import { DatePicker } from '../../../comps/DatePicker';

interface Props {
    next: any;
    back: any;
    max: number;
    min: number;
    start_date: Date;
    end_date: Date;
    setRange: any;
}

/**
 * Select Dates View
 */
export const SelectDatesView = (props: Props) => {
    return (
        <div className="page select-date-page">
            <div className="select-date-form">
                <div className="left">
                    <h1>When do you need to be seen?</h1>

                    <div className="date-selection-con-wrapper">
                        {/* Start date */}
                        {props.start_date && (<div className="date-selection">
                            Selected campaign length {format(new Date(props.start_date), 'dd/MM/yyyy')}
                        </div>)}

                        {/* End date */}
                        {props.end_date && (<div className="date-selection">
                            - {format(new Date(props.end_date), 'dd/MM/yyyy')}
                        </div>)}
                    </div>

                    {/* Start date and end date selected */}
                    {props.start_date && props.end_date && (
                        <div className="confirm-button confirm-button-desktop">
                            <Button
                                style={{
                                    maxWidth: "200px"
                                }}
                                onClick={() => props.next()}>Confirm dates</Button>
                        </div>
                    )}
                </div>

                <DatePicker
                    date={props.start_date || undefined}
                    end_date={props.end_date || undefined}
                    setRange={props.setRange}
                    min={props.min}
                    max={props.max}
                />

                {props.start_date && props.end_date && (
                    <div className="confirm-button confirm-button-mobile">
                        <Button
                            style={{
                                maxWidth: "200px"
                            }}
                            onClick={() => props.next()}>Confirm dates</Button>
                    </div>
                )}

            </div>


            {/* <div className="page-buttons">
                <Button onClick={() => props.back()} disabled={false}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <Button onClick={() => props.next()} disabled={false}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div> */}
        </div>
    );
};
