import { InlineLoader } from "../comps/InlineLoader"
import "../styles/form.layout.scss"
import { ReactNode } from "react"

export const FormLayout = ({ children, loading }: { children: ReactNode, loading?: boolean }) => {

    return (
        <div className="form">
            {loading ? <InlineLoader color="#404e3d" /> : children}
        </div>
    )
}