import '../styles/auth-bubble.comp.scss';
import { useUserStore } from '../stores/user-store';
import { faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { DropdownMenu } from './DropdownMenu';

export const AuthBubble = () => {
    const navigate = useNavigate();
    const customer = useUserStore((state: any) => state.user)?.customer || undefined;
    const setUser = useUserStore((state: any) => state.setUser);

    // Sign out the user 
    const sign_out = () => {
        // Remove the user from the store
        setUser(null);
        // Navigate to the login page
        navigate('/login');
    }

    return (
        <div className="auth-bubble">
            <div className="user-profile">
                <div className="dp-wrapper">
                    <div
                        className="dp"
                        style={{
                            backgroundImage: `url('${customer?.dp
                                ? customer.dp
                                : `https://ui-avatars.com/api/?name=${customer?.first_name}+${customer?.last_name}&background=404e3d&color=fff&size=128`
                                }')`,
                        }}
                    />
                </div>
                <div className="details">
                    <div className="name">
                        {customer?.first_name} {customer?.last_name}
                    </div>
                </div>

                <DropdownMenu
                    items={[
                        {
                            label: 'View Profile',
                            icon: faUser,
                            onClick: () => navigate(`/dash/profile`),
                        },
                        {
                            label: 'Sign out',
                            icon: faSignOut,
                            onClick: () => sign_out(),
                        },
                    ]}
                />
            </div>
        </div>
    );
};
