import "../../styles/view-vpa-order.view.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock, faDownload, faEye, faFileInvoice, faRunning, faTools } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { Package, PackageOption } from "../../defs/order-model";
import { View } from "../../layout/View";
import { ViewHeader } from "../../comps/ViewHeader";
import { Body } from "../../layout/BodyLayout";
import { Row } from "../../layout/RowLayout";
import { Container } from "../../layout/ContainerLayout";
import { StatusBar } from "../../comps/StatusBar";
import { Button } from "../../comps/Button";
import { getOrder } from "../../api/order.api";
import { SecondaryButton } from "../../comps/SecondaryButton";
import { add_commas } from "../../utils/numbers";
import { PackageCard } from "../../comps/PackageCard";
import { getCustomerPackages } from "../../api/package.api";
import { useUserStore } from "../../stores/user-store";
import { set } from "lodash";
import { Warning } from "../../comps/Warning";
import { Text } from "../../comps/Text";
import { fetchTeam } from "../../api/team.api";
import { TwoColumn } from "../../layout/TwoColumn";

export const ViewOrderView = () => {
    const user = useUserStore((state: any) => state.user);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [order, setOrder] = useState<any>(null!);
    const [options, setOptions] = useState<PackageOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [packages_loading, setPackagesLoading] = useState(true);
    const [packages, setPackages] = useState<Package[]>([]);

    const [team, setTeam] = useState<any>(null!);
    const [team_loading, setTeamLoading] = useState(false);

    // Load a team
    const load_team = async () => {

        if (!order) return;
        setTeamLoading(true);

        try {
            const team = await fetchTeam(order.team.id);

            console.log('Got the team: ', team)

            setTeam(team);
        } catch (err) {
            setError('Failed to load the team information.')
        }

        setTeamLoading(false);
    }

    // Update locally
    const update = (key: string, field: any) => {
        // Need to update nested fields, using lodash
        const updated_order = { ...order };
        setOrder(set(updated_order, key, field));
    }

    const load = async () => {
        if (!id) return;
        setLoading(true);
        setError("");

        try {
            const order = await getOrder(+id);
            setOrder(order);
        } catch (err) {
            setError("Failed to load the order.");
        }
    }

    const load_packages = async () => {
        setLoading(true);
        try {
            let packages = await getCustomerPackages(user.customer.id || 0,);
            packages = packages?.sort((a: Package, b: Package) => a.price - b.price);
            setPackages(packages);
        } catch (err) {
            console.error("Failed to load the packages: ", err);
        }
        setLoading(false);
    }


    const open_campaign_report = async () => {
        if (order || order?.report_id) {
            navigate(`/campaign-report/${order.report_id}`);
            return;
        }
    }

    useEffect(() => {
        if (id) {
            load();
            load_packages();
        }
    }, [id])

    useEffect(() => {
        if (order) load_team();
    }, [order])


    return (
        <View>
            <ViewHeader back title={"View Order"} />

            <Body>
                <Row >
                    {order?.vpa_order?.header_image && order?.vpa_order?.header_image !== "[Object] [Object]" && order?.vpa_order?.header_image !== "undefined" && <img className='order-header-image' src={order?.vpa_order?.header_image} />}
                </Row>


                <Row>
                    <Container id="no-margin">
                        <div className="order-header">
                            <div className="address-field">
                                <label>Address</label>
                                <h1>{order?.vpa_order?.address}</h1>
                            </div>

                            {/* Package Selection */}
                            <div className="package-wrapper">
                                <label>Package</label>

                                {/* List of packages */}
                                <div className="package">
                                    <div className="package-inner">
                                        <div className="package-field">
                                            {order?.vpa_order?.pack?.name}
                                        </div>
                                        <div className="package-field">
                                            <FontAwesomeIcon icon={faEye} />
                                            {add_commas(order?.vpa_order?.pack?.impressions)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="date-fields">
                            <label>Campaign Timeframe</label>
                            {order?.vpa_order?.start_date && order?.vpa_order?.end_date && (
                                <h2>From the <span id="accent-bold">{format(new Date(order?.vpa_order?.start_date), "do LLLL yyyy")}</span> till the <span id="accent-bold">{format(new Date(order?.vpa_order?.end_date), "do LLLL yyyy")}</span></h2>
                            )}
                        </div>

                        <div className="note-fields">
                            <label>Notes</label>
                            <h2>{order?.vpa_order?.notes || "N/A"}</h2>
                        </div>
                    </Container>
                </Row>

                <TwoColumn>
                    <div />
                    <div />
                    <div />
                    <div />
                    <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                    <Text label="Team" value={order?.team?.name || "N/A"} />

                    {/* List out the org and the agency */}
                    <Text label="Company" value={team?.agency?.corporation?.name || "N/A"} />
                    <Text label="Agency" value={team?.agency?.name || "N/A"} />

                </TwoColumn>

                <br />
                <br />

                <Row>
                    <Container id="no-margin">
                        <label>Order Status</label>
                        <StatusBar statuses={statuses as any} status={order?.status} start_date={new Date()} end_date={new Date()} />
                    </Container>
                </Row>


                {/* View Report Section */}
                {order?.facebook_ad_id && order?.report_id && order?.facebook_ad_id !== "[Object] [Object]" && order?.facebook_ad_id !== "undefined" && (
                    <div className="view-report-section">
                        <h2>View your live report here!</h2>
                        <p>This report is live and always up to date. Once clicked you can copy the link and share with anyone and also download a PDF version.</p>
                        <SecondaryButton onClick={open_campaign_report}><FontAwesomeIcon icon={faFileInvoice} />{" "}View Report</SecondaryButton>
                    </div>
                )}

                {/* Where is my report section */}
                {order && !order?.facebook_ad_id && order?.facebook_ad_id !== "[Object] [Object]" && order?.facebook_ad_id !== "undefined" && (
                    <Warning type={"info"} title={"Where's my report?"}>
                        <p>Once your campaign has started running a live report will be sent to your email.</p>
                        <br />
                    </Warning>
                )}

                <div className="bottom-section" />
            </Body>
        </View>
    )
}


// Hard coded statuses -> Need to grab these in the future -> To make this more dynamic?
const statuses = [{
    name: "Pending",
    color: "red",
    icon: faClock,
    tooltip: "This status indicates that the task is pending and will be changed to 'Active' once the ad has been launched."
}, {
    name: "Active",
    color: "green",
    icon: faRunning,
    tooltip: "This status indicates that the ad is currently running. It will be changed to 'Completed' when the ad is no longer running."
}, {
    name: "Completed",
    color: "blue",
    icon: faCheckCircle,
    tooltip: "This status indicates that the task has been completed."
}]