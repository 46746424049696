import "../styles/chart-tooltip.comp.scss";
import { add_commas } from "../utils/numbers";

interface Props {
    active?: any;
    payload?: any;
    label?: any;
    dataKey: string;
}

export const ChartTooltip = (props: Props) => {
    return (
        <div className="chart-tooltip">
            <div className="chart-tooltip-content">

                <div className="value">
                    {add_commas(props?.payload?.[0]?.payload?.[props.dataKey])}
                </div>
                <div className="date">
                    {props?.payload?.[0]?.payload?.date}
                </div>
            </div>
        </div>
    );
}