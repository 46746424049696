import "../../../styles/select-agent-marketing.view.scss";
import { IconDefinition, faAward, faCheck, faComments, faSign, faSignHanging, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { View } from "../../../layout/View";
import { ViewHeader } from "../../../comps/ViewHeader";
import { Button } from "../../../comps/Button";

export interface AgentMarketingSolutionOption {
    title: string;
    checked: boolean;
}

export interface AgentMarketingSolution {
    icon: IconDefinition;
    title: string;
    desc: string;
    options: AgentMarketingSolutionOption[];
}


/**
 * This component is for selecting agent marketing, it shows the options to go down.
 */
export const SelectAgentMarketingOrderView = () => {
    const navigate = useNavigate();

    // Figure out where the click should go for each solution type
    const handle_on_click = (solution: AgentMarketingSolution) => {
        console.log("Handling this: ", solution)

        switch (solution.title) {
            case "Sold Campaign":
                navigate("/dash/agent-marketing/create-sold")
                break;
            case "Testimonials":
                navigate("/dash/agent-marketing/create-test");
                break;
            case "Agent Ecosystem":
                navigate("/dash/agent-marketing/create-eco");
                break;
            default:
                break;
        }
    }

    return (
        <View>
            <ViewHeader title="Order Agent Marketing" back />
            <div className="agent-marketing-wrapper">
                <div className="agent-marketing-cta">
                    <h1>Let's get started.</h1>
                    <p>Get seen in your custom targeted areas, at a pace that meets your needs.</p>
                </div>

                <div className="agent-marketing-selection">
                    {solutions.map((solution: AgentMarketingSolution, i: number) => (
                        <AgentMarketingCard solution={solution} key={i} onClick={handle_on_click} />
                    ))}
                </div>
            </div>
        </View>
    )
}



export const AgentMarketingCard = ({ solution, onClick }: { solution: AgentMarketingSolution, onClick: any }) => {
    return (
        <div className="agent-marketing-card">
            <div className="icon">
                <FontAwesomeIcon icon={solution.icon} />
            </div>

            <h2>{solution.title}</h2>
            <p>{solution.desc}</p>

            {/* Options */}
            <div className="soltion-options">
                {solution?.options?.map((opt: AgentMarketingSolutionOption) => <div className="solution-option">
                    <div className="checked-wrapper">
                        {opt.checked ? (
                            <FontAwesomeIcon icon={faCheck} />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} />
                        )}
                    </div>
                    <label>{opt.title}</label>
                </div>
                )}
            </div>

            <Button onClick={() => onClick(solution)}>Be seen today!</Button>
        </div>
    );
}

// Define the three solutuions -> Hard coded. 
const solutions = [
    {
        icon: faSignHanging,
        title: "Sold Campaign",
        desc: "Get your property sales seen, to generate more interest in your targeted areas.",
        options: [
            {
                title: 'Customisable Budget',
                checked: true
            }, {
                title: "Flexible campaign duration",
                checked: true
            }, {
                title: "Custom tailored targeting",
                checked: true
            }
        ]
    },
    {
        icon: faComments,
        title: "Testimonials",
        desc: "Get your property sales seen, to generate more interest in your targeted areas.",
        options: [
            {
                title: 'Customisable Budget',
                checked: true
            }, {
                title: "Flexible campaign duration",
                checked: true
            }, {
                title: "Custom tailored targeting",
                checked: true
            }
        ]
    },
    {
        icon: faAward,
        title: "Agent Ecosystem",
        desc: "Get your property sales seen, to generate more interest in your targeted areas.",
        options: [
            {
                title: 'Customisable Budget',
                checked: true
            }, {
                title: "Flexible campaign duration",
                checked: true
            }, {
                title: "Custom tailored targeting",
                checked: true
            }
        ]
    }
]