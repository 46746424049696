import '../styles/date-input.comp.scss';
import DatePicker from 'react-datepicker';

interface Props {
    value: Date | null;
    onChange: (date: Date) => void;
    label?: string;
    max_date?: Date;
    min_date?: Date;
}

export const DateInput = (props: Props) => {
    return (
        <div className="date-input-wrapper">
            {props.label && <label>{props.label}</label>}

            <DatePicker
                maxDate={props.max_date}
                minDate={props.min_date}
                selected={props.value}
                onChange={(date: Date) => props.onChange(date)}
                dateFormat="dd/MM/yyyy"
            />
        </div>
    );
};
