import "../styles/org-order-view-order-modal.scss";
import { useEffect, useState } from "react"
import { ModalLayout } from "./ModalLayout"
import { Order, OrderType } from "../defs/order-model";
import { InlineLoader } from "./InlineLoader";
import { FormLayout } from "../layout/FormLayout";
import { SlideshowHeader } from "./SlideShowHeader";
import { Text } from "./Text";
import { TwoColumn } from "../layout/TwoColumn";
import { Team } from "../defs/team-model";
import { convert_type, getOrder } from "../api/order.api";
import { fetchTeam } from "../api/team.api";
import { SecondaryButton } from "./SecondaryButton";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { fe_url } from "../api/api.config";
import { format } from "date-fns";

interface Props {
    id: number;
    onClose: () => void;
}

export const OrgViewOrderModal = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<Order>(null!);
    const [error, setError] = useState('');
    const [team, setTeam] = useState<Team>(null!);

    // Get an order
    const load = async () => {
        setLoading(true);
        setError("");

        try {
            const order = await getOrder(props.id);
            setOrder(order);
        } catch (err) {
            setError("Failed to get the order.")
        }

        setLoading(false);
    }

    // Load the team
    const getTeam = async () => {
        if (!order) return;

        setLoading(true);
        setError("");

        try {
            const team = await fetchTeam(order.team_id);
            setTeam(team);
        } catch (err) {
            setError("Failed to get the team.")
        }

        setLoading(false);
    }



    useEffect(() => {
        if (order?.team) {
            getTeam();
        }
    }, [order]);

    useEffect(() => {
        load();
    }, [props.id])

    return (
        <ModalLayout>
            {loading && (<div className="loader-con">
                <InlineLoader />
            </div>)}

            {order && order?.order_type === OrderType.AM && team && (
                <AgentMarketingOrderDetails order={order} team={team} />
            )}

            {order && order?.order_type === OrderType.VPA && team && (
                <VpaOrderDetails order={order} team={team} />
            )}

        </ModalLayout>
    )
}

// View for VPA Order
export const AgentMarketingOrderDetails = ({ order, team }: { order: Order, team: Team }) => {
    const open_campaign_report = async () => {
        if (!order) return;

        if (order.report_id) {
            const report_url = `${fe_url}/agent-marketing-report/${order.report_id}`;
            window.open(report_url, "_blank");
        }
    }


    return (
        <div className="order-details-con">
            {/* Slideshow header image */}
            {order?.am_order?.amOrderMedia?.length > 0 && (
                <div className="am_header_img" >
                    <SlideshowHeader images={order?.am_order?.amOrderMedia?.map((item: any) => item.url) || []} />
                </div>
            )}

            <TwoColumn>
                <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                <Text label="Team" value={order?.team?.name || "N/A"} />

                <Text label="Corporation" value={team?.agency?.corporation?.name || "N/A"} />
                <Text label="Agency" value={team?.agency?.name || "N/A"} />

                <Text label="Type" value={convert_type(order?.am_order?.type)} />
                <Text label="Address" value={order?.am_order?.address || "N/A"} />

                <Text label="Budget" value={`$${order?.am_order?.budget / 100}` || "N/A"} />
                <Text label="Status" value={order?.status || "N/A"} />

                <Text label="Notes" value={order?.am_order?.notes || "N/A"} />
                <Text label="Date" value={order?.am_order?.date ? format(new Date(order?.am_order?.date), "do LLLL yyyy") : "N/A"} />
            </TwoColumn>

            {/* View Report if there is a link */}

            {order?.facebook_ad_id && order?.report_id && (
                <div className="view-report-section">
                    <h2>View your live report here!</h2>
                    <p>This report is live and always up to date. Once clicked you can copy the link and share with anyone and also download a PDF version.</p>
                    <SecondaryButton onClick={open_campaign_report}><FontAwesomeIcon icon={faFileInvoice} />{" "}View Report</SecondaryButton>
                </div>
            )}
        </div>
    )
}


// View for the VPA Order
export const VpaOrderDetails = ({ order, team }: { order: Order, team: Team }) => {
    const open_campaign_report = async () => {
        if (!order) return;

        if (order.report_id) {
            const report_url = `${fe_url}/campaign-report/${order.report_id}`;
            window.open(report_url, "_blank");
        }
    }

    return (
        <div className="order-details-con">
            {order?.vpa_order?.header_image && order?.vpa_order.header_image !== "undefined" && order?.vpa_order?.header_image !== "[Object] [Object]" && <img className='order-header-image' src={order?.vpa_order?.header_image} />}

            <TwoColumn>
                <Text label="Lead Customer" value={team?.lead_customer ? `${team?.lead_customer?.first_name} ${team?.lead_customer?.last_name}` : "N/A"} />
                <Text label="Team" value={order?.team?.name || "N/A"} />

                <Text label="Corporation" value={team?.agency?.corporation?.name || "N/A"} />
                <Text label="Agency" value={team?.agency?.name || "N/A"} />

                <Text label="Address" value={order?.vpa_order?.address || "N/A"} />
                <Text label="Package" value={`${order?.vpa_order?.pack?.name} - $${order?.vpa_order?.pack?.price / 100}`} />

                <Text label="Start Date" value={order?.vpa_order?.start_date ? format(new Date(order?.vpa_order?.start_date), "do LLLL yyyy") : "N/A"} />
                <Text label="End Date" value={order?.vpa_order?.end_date ? format(new Date(order?.vpa_order?.end_date), "do LLLL yyyy") : "N/A"} />

                <Text label="Status" value={order?.status || "N/A"} />
                <Text label="Notes" value={order?.vpa_order?.notes || "N/A"} />


            </TwoColumn>


            {order?.facebook_ad_id && order?.report_id && (
                <div className="view-report-section">
                    <h2>View your live report here!</h2>
                    <p>This report is live and always up to date. Once clicked you can copy the link and share with anyone and also download a PDF version.</p>
                    <SecondaryButton onClick={open_campaign_report}><FontAwesomeIcon icon={faFileInvoice} />{" "}View Report</SecondaryButton>
                </div>
            )}

        </div>
    );
}