import "../../styles/forgot-password.scss";
import { sendForgotPassword } from "../../api/customer.api";
import { Button } from "../../comps/Button";
import { Input } from "../../comps/Input";
import { Logo } from "../../comps/Logo";
import { FormLayout } from "../../layout/FormLayout";
import { useState } from "react"

export const ForgotPasswordView = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(false);

    const send_forgot_password = async () => {
        setLoading(true);
        setReset(false);

        try {
            const res = await sendForgotPassword(email);
            setReset(true);
        } catch (err) {
            console.error("Failed to reset the password. If this goes wrong something critical has gone wrong: ", err);
        }

        setLoading(false);
    }

    return (
        <>
            <div className="forgot-password-view">
                <div className="forgot-password-form-con">
                    <Logo />
                    {reset ? (
                        <div className="reset">
                            <h3>
                                All set!
                            </h3>
                            <p>
                                If you have an account associated with this email a reset password link has been sent to your inbox.
                            </p>
                        </div>
                    ) : (
                        <FormLayout>
                            <div className="con">
                                <h3>Reset Password</h3>
                                <p>Enter your email to reset your password.</p>
                            </div>
                            <Input label="Email" value={email} onChange={setEmail} disabled={loading} />
                            <Button onClick={() => send_forgot_password()} loading={loading}>Reset Password</Button>
                        </FormLayout>
                    )}
                </div>
            </div>
        </>
    )
}