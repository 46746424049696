import '../styles/dash.layout.scss';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SidePanel } from '../comps/SidePanel';
import { useUserStore } from '../stores/user-store';
import { SidePanelButton } from '../comps/SidePanelButton';
import {
    faBuilding,
    faCartPlus,
    faFileInvoice,
    faHome,
    faSignHanging,
} from '@fortawesome/free-solid-svg-icons';
import { CopyBar } from '../comps/CopyBar';
import { fetchCustomer } from '../api/customer.api';

export const CustomerLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user_auth = useUserStore((state: any) => state.user);
    const setUser = useUserStore((state: any) => state.setUser);
    const [page, setPage] = useState('');

    // See if we can actually get the logged in user
    const check_user = async () => {
        try {
            const user = await fetchCustomer(user_auth?.customer?.id);

            if (!user) {
                navigate('/login');
                setUser(null);
            }
        } catch (err) {
            navigate('/login');
            setUser(null);
        }
    };

    // Look at the URL and determine what side panel button should be highlighted
    useEffect(() => {
        // Split the location pathname
        let paths = location.pathname.split('/');
        let path = paths.length === 2 ? '/' : paths[2];

        // Check the location
        if (path === 'home') {
            setPage('home');
        } else if (path === 'order-vpa-campaign') {
            setPage('order-vpa-campaign');
        } else if (path === 'orders') {
            setPage('orders');
        } else if (path === "org") {
            setPage("org");
        } else if (path === 'agent-marketing') {
            setPage("agent-marketing");
        } else {
            setPage('');
        }
    }, [location]);

    useEffect(() => {
        if (!user_auth) {
            navigate('/login');
        } else {
            check_user();
        }
    }, [user_auth, navigate]);

    return (
        <div className="staff-layout dash-layout">
            <SidePanel>
                <div className="inner-menu-items">
                    <SidePanelButton
                        icon={faHome}
                        label="Home"
                        active={page === 'home'}
                        navigate="home"
                    />
                    {user_auth?.customer?.role === "CORP_ADMIN" && <SidePanelButton
                        icon={faBuilding}
                        label="Organisation"
                        active={page === 'org'}
                        navigate="org"
                    />}
                    <SidePanelButton
                        icon={faFileInvoice}
                        label="Campaigns"
                        active={page === 'orders'}
                        navigate="orders"
                    />
                    <SidePanelButton
                        icon={faCartPlus}
                        label="Order Campaign"
                        active={page === 'order-vpa-campaign'}
                        navigate="order-vpa-campaign"
                    />
                    <SidePanelButton
                        icon={faSignHanging}
                        label="Order Agent Marketing"
                        active={page === "agent-marketing"}
                        navigate='agent-marketing'
                    />
                </div>
            </SidePanel>

            <div className="content">
                <Outlet />
            </div>

            <CopyBar />
        </div>
    );
};
