import { useState } from "react";
import { addAgency } from "../api/agency.api";
import { useUserStore } from "../stores/user-store";
import { ModalLayout } from "./ModalLayout";
import { FormLayout } from "../layout/FormLayout";
import { Input } from "./Input";
import { Button } from "./Button";

interface Props {
    onAdd: () => void;
}

export const AddAgencyModal = (props: Props) => {
    const user = useUserStore((state: any) => state.user);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const add = async () => {
        setLoading(true);
        setError("");

        try {
            await addAgency({
                name,
                address,
                corporation_id: user.corporation.id
            });
            props.onAdd();
        } catch (err) {
            setError("Failed to add the agency...");
        }

        setLoading(false);
    }

    return (
        <ModalLayout>
            <FormLayout>
                <Input label="Name" value={name} onChange={setName} />
                <Input label="Address" value={address} onChange={setAddress} />
                <Button onClick={add} loading={loading}>Create!</Button>
            </FormLayout>
        </ModalLayout>
    )
}