import "../styles/select.comp.scss"
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export interface Option {
    label: string;
    value: any;
    color?: string;
}

interface Props {
    label?: string;
    value: Option | undefined;
    onChange: (value: Option) => void;
    options: Option[];
    placeholder?: string;
}

export const Select = (props: Props) => {
    const [open, setOpen] = useState(false);

    // Handle any colours
    const handle_block_color = (color?: string) => {
        if (!color) return
        return {
            backgroundColor: color,
            color: "#232323"
        }
    }

    const handle_outline_color = (color?: string) => {
        if (!color) return
        return {
            borderColor: color,
        }
    }

    return (
        <div className="select-wrapper" onClick={() => setOpen(!open)}>
            {props.label && <label>{props.label}</label>}

            {/* Select value */}
            <div className="select-value"
                style={{
                    ...handle_outline_color(props.value?.color)
                }}
            >
                {props?.value?.label || props?.placeholder || "Select an option..."}

                <div className="drop-down-arrow">
                    <FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />
                </div>
            </div>

            {/* Select options */}
            <div className="select-options" id={open ? "open" : "closed"}>
                <div className="select-options-inner">
                    {props.options.map((option, i) => (
                        <div
                            key={i}
                            className="select-option"
                            style={{
                                ...handle_block_color(option?.color)
                            }}
                            onClick={() => props.onChange(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}