import "../styles/agent-marketing-form.layout.scss";

import { ReactNode } from "react"

interface Props {
    children: ReactNode;
}

export const AgentMarketingForm = (props: Props) => {

    return (
        <div className="agent-marketing-form">
            {props.children}
        </div>
    )

}