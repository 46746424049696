import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Customer } from '../defs/customer-model';
import { Corporation } from '../defs/corporation-model';

export interface AuthResult {
    token: string;
    customer: Customer;
    corporation: Corporation;
}

export const useUserStore = create(
    persist(
        (set, get) => ({
            user: null as AuthResult | null,
            setUser: (user: AuthResult | null) => set({ user }),
        }),
        {
            name: 'user-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);
