import "../../../styles/select-address.create-order.scss";
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Coords, geocodeAddress } from '../../../api/places.api';
import { AddressInput } from '../../../comps/AddressInput';
import { Button } from '../../../comps/Button';
import { Map } from '../../../comps/Map';


interface Props {
    address: string;
    setAddress: any;
    next: any;
    back: any;
}

/**
 * Select an address for creating a new order here
 */
export const SelectAddressView = (props: Props) => {
    const [address_set, setAddressSet] = useState(false);
    const [coords, setCoords] = useState<Coords>(null!);

    // Gecode the address
    const geocode = async () => {
        const temp = await geocodeAddress(props.address);
        if (temp) setCoords(temp);
    };

    useEffect(() => {
        if (props.address) geocode();
    }, [address_set]);

    return (
        <div className="page address-page">
            <div className="left">
                <h1>What's your property's address?</h1>

                <AddressInput
                    label="Address"
                    value={props.address}
                    set={address_set}
                    onChange={(v: string) => {
                        setAddressSet(false);
                        props.setAddress(v);
                    }}
                    onSet={(v: string) => {
                        setAddressSet(true);
                        props.setAddress(v);
                    }}
                />

                <div className="confirm-button confirm-button-desktop">
                    <Button
                        disabled={!props.address}
                        style={{
                            maxWidth: "200px"
                        }}
                        onClick={() => props.next()}>
                        Confirm Address
                    </Button>
                </div>
            </div>

            {/* Map component */}
            <div className="page-map-wrapper">
                <Map coords={coords} zoom={4} />
            </div>

            <div className="confirm-button confirm-button-mobile">
                <Button
                    disabled={!props.address}
                    style={{
                        maxWidth: "200px"
                    }}
                    onClick={() => props.next()}>
                    Confirm Address
                </Button>
            </div>

        </div>
    );
};
