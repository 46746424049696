import "../styles/bar-chart-card.comp.scss"
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add_commas } from "../utils/numbers";
import { Bar, BarChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { InlineLoader } from "./InlineLoader";

import "../styles/bar-chart-tooltip.comp.scss";
import { capitalize } from "lodash";


interface Props {
    data: any[];
    title: string;
    total: number;
    icon: IconDefinition;
}

export const BarChartCard = (props: Props) => {

    return (
        <div className="bar-chart-card">
            <div className="bar-chart-card-header">
                <div className="bar-chart-card-icon">
                    <FontAwesomeIcon icon={props.icon} />
                </div>
                <div>
                    <div className="bar-chart-total">
                        {add_commas(props.total || 0)}
                    </div>
                    <h3>{props.title}</h3>
                </div>
            </div>

            {(!props.data || props.data?.length <= 0) && (<div className="bar-chart-card-loader">
                <InlineLoader color="#232323" />
            </div>)}

            <div className="bar-chart-wrapper">
                {props.data && (
                    <ResponsiveContainer width="100%" height={250}>
                        <BarChart data={props.data} barSize={25} barCategoryGap={15} style={{
                            stroke: "#dadad2", strokeWidth: 4
                        }}>
                            <Bar dataKey="male" fill="#1a3368" stackId={1} radius={4} isAnimationActive={false} />
                            <Bar dataKey="female" fill="#DC1C2E" stackId={1} radius={4} isAnimationActive={false} />
                            <Tooltip cursor={{
                                fill: "#ffffff22",
                                radius: 15
                            }} content={<BarChartTooltip dataKeys={["female", "male"]} xKey="age" />} />
                            <XAxis dataKey={'age'} style={{
                                strokeWidth: 0
                            }} />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div >
    )
}

interface BProps {
    active?: any;
    payload?: any;
    label?: any;
    dataKeys: string[]; // This is each bars data keys per bar result
    xKey: string; // This is the x axis data key
}

const BarChartTooltip = (props: BProps) => {
    return (
        <div className="bar-chart-tooltip">
            <div className="bar-chart-tooltip-content">
                {/* Bar chart keys */}

                {props.dataKeys.map((key: string) => (
                    <div className="data-point" key={key}>{capitalize(key)}: {props.payload?.[0]?.payload?.[key]}</div>
                ))}

                {/* X axis */}
                <div className="x-axis-label">
                    {capitalize(props?.payload?.[0]?.payload?.[props.xKey])}
                </div>

            </div>
        </div>
    )
}   